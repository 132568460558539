import React, { useContext, useEffect, useState } from 'react'
import { Box, Button, Container, FormControlLabel, Grid, Stack, Typography } from '@mui/material'
import { SubmitHandler, useForm } from 'react-hook-form'
import { DatePickerSeparator, Input, Radio, Select } from 'components'
import { useAPI } from 'lib/api/api'
import { AuthContext } from 'screens/AuthProvider'
import { countryOption, dataPrefectureJa, sexOptions, typeSite } from 'lib/constant'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import * as yup from 'yup'
import dayjs from 'dayjs'
import toast from 'react-hot-toast'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAuth0 } from '@auth0/auth0-react'
import { Loading } from 'components/Loader'
import '../register/button.css'

const emojiRegex =
  /^[^\u{1F600}-\u{1F6FF}\u{1F300}-\u{1F5FF}\u{1F900}-\u{1F9FF}\u{1F1E6}-\u{1F1FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]*$/u
const katakanaRegex = /^[\u30A0-\u30FF]+$/

const commonSchema = {
  first_name: yup
    .string()
    .required('名は必須フィールドです。')
    .matches(emojiRegex, '不正な文字が含まれています。'),
  first_name_kana: yup
    .string()
    .required('メイ（カナ）は必須フィールドです。')
    .matches(katakanaRegex, 'カタカナで入力してください。'),
  family_name: yup
    .string()
    .required('姓は必須フィールドです。')
    .matches(emojiRegex, '不正な文字が含まれています。'),
  family_name_kana: yup
    .string()
    .required('セイ（カナ）は必須フィールドです。')
    .matches(katakanaRegex, 'カタカナで入力してください。'),
  gender: yup
    .number()
    .typeError('性別は有効である必要があります。')
    .required('性別は必須フィールドです。'),
  dob: yup
    .date()
    .typeError('生年月日は有効である必要があります。')
    .required('生年月日は必須フィールドです。')
    .max(new Date(), '現在の日付以降の日付を選択してください。'),
  tel: yup
    .string()
    .required('電話番号は必須フィールドです。')
    .matches(
      /^(?=[\d-]{7,14}$)(?=(?:[^-]*-){2}[^-]*$)[\d-]+$/,
      '電話番号の形式が正しくありません。ハイフンを2つ含み、7文字以上14文字以下で入力してください。'
    ),
  email: yup
    .string()
    .required('メールアドレスは必須フィールドです。')
    .email('メールアドレスが無効です。')
    .matches(
      /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/,
      'メールアドレスが無効です。'
    ),
  city: yup
    .string()
    .required('市区町村は必須フィールドです。')
    .matches(emojiRegex, '不正な文字が含まれています。'),
  address1: yup.string().matches(emojiRegex, '不正な文字が含まれています。'),
  address2: yup.string().matches(emojiRegex, '不正な文字が含まれています。')
}

const validate = yup.object({ ...commonSchema })

const validateOTP = yup.object({
  ...commonSchema,
  otp: yup
    .string()
    .typeError('認証コードは必須フィールドです。')
    .required('認証コードは必須フィールドです。')
})

const validatePostalCode = yup.object({
  ...commonSchema,
  postal_code: yup
    .string()
    .required('郵便番号は必須項目です。')
    .matches(/^([0-9]{7})?$/, '郵便番号の形式は正しくありません。'),
  prefecture: yup
    .number()
    .required('都道府県は必須フィールドです。')
    .typeError('都道府県は必須フィールドです。')
})

const Information: React.FC = () => {
  const { user, updateUser, fetchUser } = useContext(AuthContext)
  const api = useAPI()
  const navigate = useNavigate()
  const { user: userAuth0, isLoading, isAuthenticated, loginWithRedirect } = useAuth0()
  const [btnLoading, setBtnLoading] = useState<boolean>(false)
  const [valueEmail, setValueEmail] = useState<string>('')
  const [checkValue, setCheckValue] = useState<boolean>(false)
  const [loadingSend, setLoadingSend] = useState<boolean>(false)
  const [country, setCountry] = useState<number>(1)

  const [searchParams] = useSearchParams()
  useEffect(() => {
    if (searchParams) {
      for (const param of searchParams) {
        localStorage.setItem(`${param[0]}`, param[1])
      }
      if (window.location.href.indexOf('?') > -1) {
        navigate(`/information`)
      }
    } else {
      localStorage.setItem('type', '')
    }
  }, [searchParams])

  const checkEvent = async () => {
    try {
      const res = await api.fetcher('get', `/customer/info`)
      if (res) {
        updateUser(res.user_metadata)
      }
      return
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    checkEvent()
  }, [])

  const checkValidate = (checkValue: boolean, country: number) => {
    if (checkValue && country === countryOption.JAPAN) {
      return validateOTP.concat(validatePostalCode)
    } else if (checkValue) {
      return validateOTP
    } else if (country === countryOption.JAPAN) {
      return validatePostalCode
    } else {
      return validate
    }
  }

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { isSubmitting, errors }
  } = useForm<App.Models.UserInformationAuth0>({
    defaultValues: {
      first_name: '',
      first_name_kana: '',
      family_name: '',
      family_name_kana: '',
      tel: '',
      gender: undefined,
      dob: '',
      postal_code: '',
      prefecture: undefined,
      city: '',
      address1: '',
      address2: '',
      email: '',
      otp: ''
    },
    resolver: yupResolver(checkValidate(checkValue, country)),
    mode: 'all'
  })

  const handleClearSession = () => {
    const listKey = ['type', 'site', 'token', 'site', 'email', 'email-regis', 'sub']
    listKey.map((item) => localStorage.removeItem(item))
    sessionStorage.removeItem('sub')
    sessionStorage.removeItem('informationRoute')
  }

  const handleBack = () => {
    if (localStorage.getItem('type') === typeSite[1]) {
      window.location.href = `${localStorage.getItem('site')}/mypage`
    } else if (localStorage.getItem('type') === typeSite[2]) {
      window.location.href = `${localStorage.getItem('site')}/account`
    } else if (localStorage.getItem('type') === typeSite[3]) {
      window.location.href = `${localStorage.getItem('site')}/account`
    } else {
      navigate('/')
    }
    handleClearSession()
  }

  const onSubmit: SubmitHandler<App.Models.UserInformationAuth0> = async (value) => {
    try {
      const _value = {
        ...value,
        dob: dayjs(value.dob).format('YYYY-MM-DD'),
        type: localStorage.getItem('type') ?? null,
        prefecture: country === countryOption.JAPAN ? value.prefecture : 99
      }

      const res = await api.fetcher('post', `/customer/update/metadata`, _value, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') }
      })

      if (res.is_reload === true) {
        toast.success('アカウント情報の変更が完了しました。再度ログインしてください。', {
          duration: 4000
        })
        setTimeout(loginWithRedirect, 3000)

        return
      }
      fetchUser()
      toast.success('アカウント情報の変更が完了しました。', { duration: 4000 })
      sessionStorage.removeItem('informationRoute')
      handleBack()
    } catch (error) {
      toast.error(error.data.message)
    }
  }

  useEffect(() => {
    if (user) {
      for (const name in user) {
        setValue(
          name as keyof App.Models.UserInformationAuth0,
          user[name as keyof App.Models.UserInformationAuth0]
        )
      }
      setValue('prefecture', Number(user.prefecture))
      setValueEmail(user.email)
      if (Number(user.prefecture) === 99) {
        setCountry(countryOption.ABOARD)
      } else {
        setCountry(countryOption.JAPAN)
      }
    }
  }, [user])

  useEffect(() => {
    if (country === countryOption.JAPAN) {
      setValue('prefecture', undefined)
      return
    }
  }, [country])

  const handleChangeZip = async () => {
    setBtnLoading(true)
    try {
      const res = await api.fetcher('get', `/maps/geocode?postal_code=${watch('postal_code')}`)
      if (res.country === '日本') {
        setValue(
          'prefecture',
          Number(dataPrefectureJa.find((e) => e.label === res.administrative_area_level_1)?.value)
        )
        setValue('city', res.locality)
        setValue(
          'address1',
          res.sublocality_level_1
            ? res.sublocality_level_1 + res.sublocality_level_2
            : res.sublocality_level_2
        )
        clearErrors('postal_code')
        clearErrors('prefecture')
        clearErrors('city')
      } else {
        clearErrors('postal_code')
      }
    } catch (error) {
      setError('postal_code', { message: error.data.message })
    } finally {
      setBtnLoading(false)
    }
  }

  useEffect(() => {
    if (!isLoading) {
      if (!isAuthenticated) {
        return navigate('/login')
      }
    }
  }, [isLoading, isAuthenticated, userAuth0])

  useEffect(() => {
    if (watch('email') !== valueEmail) {
      setCheckValue(true)
      return
    }
    setCheckValue(false)
  }, [watch('email'), valueEmail])

  const handleSendOTP = async () => {
    try {
      const newEmail = watch('email')
      const regex = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i

      if (!regex.test(newEmail)) {
        setError('email', { message: 'メールアドレスが無効です。' })
      } else {
        clearErrors('email')
        setLoadingSend(true)
        const res = await api.fetcher('post', '/customer/otp/change/email', {
          new_email: newEmail
        })

        if (res) {
          toast.success('認証コードを送信しました。ご確認ください。', {
            style: {
              border: '1px solid #713200',
              padding: '16px',
              color: '#713200'
            },
            iconTheme: {
              primary: '#713200',
              secondary: '#FFFAEE'
            }
          })
          setLoadingSend(false)
        }
      }
    } catch (error) {
      setLoadingSend(false)
      toast.error(error.data.message)
    }
  }

  const handleNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = ''

    if (country === countryOption.JAPAN) {
      inputValue = event.target.value.replace(/[^0-9]/g, '')
    } else {
      inputValue = event.target.value.replace(/[^a-zA-Z0-9]/g, '').slice(0, 20)
    }

    setValue('postal_code', inputValue)
  }

  const handleChangeCountry = (value: number) => {
    setCountry(value)
    setValue('postal_code', '')

    if (value === countryOption.ABOARD) {
      clearErrors('postal_code')
      setValue('prefecture', undefined)
    }
  }

  if (!user) {
    return <Loading />
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Container
        maxWidth="home"
        sx={{
          backgroundColor: '#FFFEFB',
          p: 2,
          borderRadius: '22px',
          boxShadow: '4px 4px 7px 0px #7775754D, -4px -4px 6px 0px #F7F5F3',
          mt: 3
        }}
      >
        <Typography variant="h6" textAlign="center" mb={3}>
          アカウント情報の変更
        </Typography>

        <Stack spacing={2}>
          <Stack
            direction={{ md: 'row', xs: 'column' }}
            spacing={2}
            alignItems={{ xs: 'start', md: 'center' }}
          >
            <Stack spacing={1} width="100%">
              <Input
                control={control}
                name="email"
                label="メールアドレス"
                required
                fullWidth
                placeholder="メールアドレスを入力"
              />

              {checkValue && (
                <Typography
                  variant="caption"
                  color="#989898"
                  sx={{ '&.MuiTypography-root': { marginLeft: 1 } }}
                >
                  認証コードの確認が必要です。変更後のメールアドレスに認証コードを送ります
                </Typography>
              )}
            </Stack>

            {checkValue && (
              <LoadingButton
                variant="outlined"
                sx={{
                  borderRadius: 23,
                  color: '#989898',
                  border: '1px solid #e0deda',
                  ':hover': {
                    border: '1px solid #e0deda'
                  },
                  width: 180,
                  '&.MuiButtonBase-root': {
                    marginTop: { xs: 1, md: errors.email ? -3 : 0 }
                  }
                }}
                onClick={handleSendOTP}
                loading={loadingSend}
                className="button"
              >
                認証コードを再送
              </LoadingButton>
            )}
          </Stack>

          {checkValue && (
            <Input
              name="otp"
              control={control}
              label="認証コード"
              required
              sx={{ width: { md: '49%' } }}
              placeholder="認証コードを入力"
            />
          )}

          <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}>
            <Input
              control={control}
              name="family_name"
              label="姓"
              fullWidth
              required
              placeholder="姓を入力"
            />
            <Input
              control={control}
              name="first_name"
              label="名"
              fullWidth
              required
              placeholder="名を入力"
            />
          </Stack>

          <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}>
            <Input
              control={control}
              name="family_name_kana"
              label="セイ（カナ）"
              fullWidth
              required
              placeholder="セイ（カナ）を入力"
            />
            <Input
              control={control}
              name="first_name_kana"
              label="メイ（カナ）"
              fullWidth
              required
              placeholder="メイ（カナ）を入力"
            />
          </Stack>

          <Radio control={control} name="gender" label="性別" options={sexOptions} required />

          <DatePickerSeparator control={control} name="dob" label="生年月日" required />

          <Grid container>
            <Grid item xs={12} md={6}>
              <Input
                control={control}
                name="tel"
                label="電話番号"
                fullWidth
                required
                placeholder="例: 090-0000-0000"
              />
            </Grid>
          </Grid>

          <Stack spacing={1}>
            <Stack direction="row">
              <Typography>郵便番号</Typography>
              {country === countryOption.JAPAN && (
                <Typography ml={0.5} variant="caption" color="error.main">
                  *
                </Typography>
              )}
            </Stack>
            <form className="form" style={{ marginLeft: 4 }}>
              <FormControlLabel
                value="japan"
                control={
                  <input type="radio" name="japan" checked={country === countryOption.JAPAN} />
                }
                label="日本"
                onChange={() => handleChangeCountry(countryOption.JAPAN)}
              />

              <FormControlLabel
                value="overseas"
                control={
                  <input type="radio" name="overseas" checked={country === countryOption.ABOARD} />
                }
                label="海外"
                onChange={() => handleChangeCountry(countryOption.ABOARD)}
              />
            </form>

            <Grid container alignItems="center">
              <Grid item xs={7} md={4}>
                <Input
                  control={control}
                  name="postal_code"
                  label=""
                  fullWidth
                  placeholder="例: 1010101"
                  controlProps={{
                    onChange: handleNumber
                  }}
                />
              </Grid>

              {country === countryOption.JAPAN && (
                <Grid
                  item
                  xs={4}
                  md={2}
                  mt={{ xs: errors.postal_code ? -5 : 0, md: errors.postal_code ? -3 : 0 }}
                  ml={{ xs: 1, md: 2 }}
                >
                  <LoadingButton
                    variant="outlined"
                    sx={{
                      borderRadius: 23,
                      color: '#211D1A',
                      border: '1px solid #211D1A',
                      ':hover': {
                        border: '1px solid #e0deda'
                      }
                    }}
                    onClick={handleChangeZip}
                    loading={btnLoading}
                    className="button"
                  >
                    住所を入力
                  </LoadingButton>
                </Grid>
              )}
            </Grid>
          </Stack>

          {country === countryOption.JAPAN && (
            <Grid container>
              <Grid item xs={12} md={6}>
                <Select
                  control={control}
                  name="prefecture"
                  label="都道府県"
                  options={dataPrefectureJa}
                  required
                  placeholder="都道府県を入力"
                  fullWidth
                />
              </Grid>
            </Grid>
          )}

          <Input
            control={control}
            name="city"
            label="市区町村"
            fullWidth
            placeholder="例: 港区"
            required
          />

          <Input
            control={control}
            name="address1"
            label="それ以降の住所"
            fullWidth
            placeholder="例: 新橋3丁目3-13"
          />

          <Input
            control={control}
            name="address2"
            label="建物名、部屋番号など"
            fullWidth
            placeholder="例: ○×マンション 101号室"
          />

          <Typography textAlign="center">ご利用中のサービスの情報が一括で変更されます</Typography>
        </Stack>

        <Stack mt={4} spacing={2} direction="row" justifyContent="center">
          <Button
            onClick={() => handleBack()}
            variant="outlined"
            sx={{
              height: 40,
              borderRadius: 23,
              color: '#989898',
              border: '1px solid #e0deda',
              ':hover': {
                border: '1px solid #e0deda'
              },
              width: 100
            }}
            disabled={isSubmitting}
            className="button"
          >
            戻る
          </Button>

          <LoadingButton
            type="submit"
            loading={isSubmitting}
            sx={{ width: 150, borderRadius: 23, color: '#111', height: 40 }}
            className="buttonVariant"
          >
            変更
          </LoadingButton>
        </Stack>
      </Container>
    </Box>
  )
}

export { Information }
