import { Box, ContainerProps } from '@mui/material'
import logo from 'assets/Logo.png'
import { StepperStyled } from '.'

export type FormLayoutType = {
  step?: number
  logoPath?: string
}

const FormLayout: React.FC<ContainerProps<'div', FormLayoutType>> = ({ children, step }) => {
  return (
    <Box
      sx={{
        background: 'linear-gradient(168.64deg, #FDFCF9 -0.36%, #F9F7F5 99.47%)',
        minHeight: '100vh',
        py: '20px',
        px: { xs: '12px', md: '20px' }
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 24,
          paddingTop: 10,
          paddingBottom: 10
        }}
      >
        <img src={logo} width={300} />
      </div>
      {step && (
        <Box mb={{ xs: 3, md: 4 }}>
          <StepperStyled step={Number(step)} />
        </Box>
      )}
      {children}
    </Box>
  )
}

export { FormLayout }
