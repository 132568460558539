import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material'
import { Props } from './AccountForm'
import { DatePickerSeparator, Input, Radio } from 'components'
import { sexOptions } from 'lib/constant'
import { FormLayout } from 'components/Layouts'
import { useAuth0 } from '@auth0/auth0-react'
import { useSearchParams } from 'react-router-dom'

const InfoForm: React.FC<Props & { handleNext: () => void; handleBack: () => void }> = ({
  control,
  handleNext,
  handleBack
}) => {
  const { logout } = useAuth0()
  const [searchParams] = useSearchParams()

  return (
    <FormLayout step={searchParams.get('policy') === 'true' ? 2 : 3}>
      <Container maxWidth="home" sx={{ px: 0 }}>
        <Box
          sx={{
            backgroundColor: '#FFFEFB',
            p: 2,
            borderRadius: '22px',
            boxShadow: '4px 4px 7px 0px #7775754D, -4px -4px 6px 0px #F7F5F3'
          }}
        >
          <Box>
            <Typography variant="h6" textAlign="center">
              アカウント情報を入力してください
            </Typography>
            <Stack spacing={2} mt={2}>
              <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                <Input
                  control={control}
                  name="family_name"
                  label="姓"
                  fullWidth
                  required
                  placeholder="姓を入力"
                />
                <Input
                  control={control}
                  name="first_name"
                  label="名"
                  fullWidth
                  required
                  placeholder="名を入力"
                />
              </Stack>

              <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                <Input
                  control={control}
                  name="family_name_kana"
                  label="セイ（カナ）"
                  fullWidth
                  required
                  placeholder="セイ（カナ）を入力"
                />
                <Input
                  control={control}
                  name="first_name_kana"
                  label="メイ（カナ）"
                  fullWidth
                  required
                  placeholder="メイ（カナ）を入力"
                />
              </Stack>

              <Radio control={control} name="gender" label="性別" options={sexOptions} required />

              <DatePickerSeparator control={control} name="dob" label="生年月日" required />

              <Grid container>
                <Grid item xs={12} md={6}>
                  <Input
                    control={control}
                    name="tel"
                    label="電話番号"
                    fullWidth
                    required
                    placeholder="例: 090-0000-0000"
                  />
                </Grid>
              </Grid>
            </Stack>
          </Box>

          <Stack alignItems="center" mt={4} direction="row" spacing={2} justifyContent="center">
            {searchParams.get('policy') === 'true' && (
              <Button
                sx={{
                  height: 40,
                  borderRadius: 23,
                  color: '#989898',
                  border: '1px solid #e0deda',
                  ':hover': {
                    border: '1px solid #e0deda'
                  },
                  width: 100
                }}
                onClick={handleBack}
                className="button"
              >
                戻る
              </Button>
            )}

            <Button
              sx={{
                width: 100,
                height: 40,
                borderRadius: 23,
                color: '#111'
              }}
              onClick={handleNext}
              className="buttonVariant"
            >
              次へ
            </Button>
          </Stack>

          <Typography
            style={{
              fontSize: 12,
              color: '#111',
              justifyContent: 'center',
              display: 'flex',
              marginTop: 16,
              textDecoration: 'underline',
              cursor: 'pointer'
            }}
            onClick={() => logout()}
          >
            ログインに戻る
          </Typography>
        </Box>
      </Container>
    </FormLayout>
  )
}

export { InfoForm }
