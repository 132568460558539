import { useAuth0 } from '@auth0/auth0-react'
import { Box, Button, Container, Stack, Typography } from '@mui/material'
import { shopifyToken, updateFirstLogin } from 'lib/api'
import { typeSite } from 'lib/constant'

const Confirm: React.FC = () => {
  const type = localStorage.getItem('type')
  const { getAccessTokenSilently, user } = useAuth0()

  const redirectShopify = async () => {
    const urlShopify = `${localStorage.getItem('site')}/account/login`

    const domain = import.meta.env.VITE_APP_AUTH0_DOMAIN
    const accessToken = await getAccessTokenSilently()

    const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user?.sub}`
    const metadataResponse = await fetch(userDetailsByIdUrl, {
      headers: { Authorization: `Bearer ${accessToken}` }
    })
    const { user_metadata } = await metadataResponse.json()

    const response = await shopifyToken({ email: user_metadata?.email })
    const listKey = ['site', 'type', 'is_reload', 'first_name', 'family_name']

    if (response.data.isLink) {
      window.location.href = `${urlShopify}/multipass/${response.data.token}`
      listKey.map((item) => localStorage.removeItem(item))
      return
    }

    window.location.href = `${urlShopify}/?accessToken=${response.data.token}&emailExists=${response.data.emailExists}&activeForm=true&emailAuth0=${user_metadata?.email}&firstName=${user_metadata?.first_name}&lastName=${user_metadata?.family_name}`
    listKey.map((item) => localStorage.removeItem(item))
  }

  const handleYes = async () => {
    try {
      await updateFirstLogin()
      localStorage.setItem('redirect', 'true')

      if (type === typeSite[2]) {
        redirectShopify()
        window.open(`${import.meta.env.VITE_APP_KUROCO_URL}`, '_blank')
        localStorage.removeItem('redirect')
      } else if (type === typeSite[3]) {
        window.open(import.meta.env.VITE_APP_KUROCO_URL, '_blank')
        window.location.href = `${localStorage.getItem('site')}`
        localStorage.removeItem('redirect')
      }
    } catch (error) {
      console.log({ error })
    }
  }

  const handleNo = async () => {
    try {
      await updateFirstLogin()
      localStorage.setItem('redirect', 'true')

      if (type === typeSite[2]) {
        redirectShopify()
      } else if (type === typeSite[3]) {
        window.location.href = `${localStorage.getItem('site')}`
      }
    } catch (error) {
      console.log({ error })
    }
  }

  return (
    <Container maxWidth="sm" sx={{ px: 0, mt: 3 }}>
      <Box
        sx={{
          backgroundColor: '#FFFEFB',
          p: 2,
          borderRadius: '22px',
          boxShadow: '4px 4px 7px 0px #7775754D, -4px -4px 6px 0px #F7F5F3'
        }}
      >
        <Typography variant="h5" textAlign="center" p={2}>
          <span style={{ fontSize: '1.0rem', textAlign: 'start' }}>
            オアシス会員（旧リゾナス美容研究所）ですか？
          </span>
          <br />
          <span style={{ fontSize: '0.8rem', textAlign: 'start' }}>
            ※会員特典を受けるために、先にアカウント引継ぎをお願い致します。
          </span>
        </Typography>

        <Stack spacing={10} direction="row" justifyContent="center" mt={6} mb={2}>
          <Button
            sx={{
              height: 40,
              borderRadius: 2,
              color: '#111',
              border: '1px solid #e0deda',
              ':hover': {
                border: '1px solid #e0deda'
              },
              width: 100
            }}
            onClick={handleNo}
            className="button"
          >
            いいえ
          </Button>
          <Button
            className="buttonVariant"
            onClick={handleYes}
            sx={{ height: 40, borderRadius: 2, color: '#111', width: 100 }}
          >
            はい
          </Button>
        </Stack>
      </Box>
    </Container>
  )
}

export { Confirm }
