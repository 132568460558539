import axios from 'axios'

const service = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL,
  timeout: 10 * 1000 // request timeout
})

service.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => {
    // console.log(error) // for debug
    Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  (response) => {
    return response
  },

  async (error) => {
    const originalRequest = error.config

    if (error.code === 'ECONNABORTED' && !originalRequest._retry) {
      originalRequest._retry = true
      return service(originalRequest)
    }

    return Promise.reject(error)
  }
)

export default service
