import { Box, CssBaseline } from '@mui/material'
import Logo from 'assets/Logo.png'

const Header: React.FC = () => {
  return (
    <>
      <CssBaseline />

      <Box sx={{ display: 'flex', justifyContent: 'center', paddingY: '10px' }}>
        <img src={Logo} width={300} />
      </Box>
    </>
  )
}

export { Header }
