import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

type RequireAuthProps = {
  children: JSX.Element
}

const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
  const location = useLocation()

  if (window.location.href.includes('/login') || window.location.href.includes('?code')) {
    return <Navigate to="/login" state={{ from: location }} />
  }

  return children
}

export { RequireAuth }
