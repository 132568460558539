import { SubmitHandler, useForm } from 'react-hook-form'
import { AccountForm, OTP } from '.'
import { useSearchParams } from 'react-router-dom'
import { useState } from 'react'
import { useAPI } from 'lib/api/api'
import toast from 'react-hot-toast'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import auth0 from 'auth0-js'

const schemaAccount = yup.object({
  email: yup
    .string()
    .required('メールアドレスは必須項目です。')
    .email('メールアドレスが無効です。')
    .matches(
      /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/,
      'メールアドレスが無効です。'
    ),
  password: yup
    .string()
    .required('パスワードは必須項目です。')
    .min(8, 'パスワードは8文字以上でなければなりません')
    .matches(
      /^(?=.{8,})((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*/,
      'パスワードは8文字以上で、大文字・小文字・数字・記号のうち3種類以上を使用してください'
    ),
  confirm_password: yup
    .string()
    .required('パスワードの確認は必須項目です。')
    .oneOf([yup.ref('password'), ''], 'パスワードが一致しません。')
})

const SignUp: React.FC = () => {
  const {
    control,
    setValue,
    formState: { isSubmitting },
    handleSubmit
  } = useForm<any>({
    defaultValues: {
      email: '',
      password: ''
    },
    resolver: yupResolver(schemaAccount as any),
    mode: 'all',
    shouldUnregister: false
  })

  const [searchParams] = useSearchParams()
  const [signUpStep, setSignUpStep] = useState<number>(0)
  const api = useAPI()
  // const { loginWithRedirect } = useAuth0()
  // const { updateUser, fetchUser } = useContext(AuthContext)
  // const navigate = useNavigate()

  const webAuth = new auth0.WebAuth({
    domain: import.meta.env.VITE_APP_AUTH0_DOMAIN,
    clientID: import.meta.env.VITE_APP_AUTH0_CLIENT_ID
  })

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <AccountForm
            control={control}
            searchParams={searchParams}
            setValue={setValue}
            loadingCheck={isSubmitting}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
          />
        )
      case 1:
        return <OTP handleBack={handleBack} />
      case 2:
      default:
        return 'ERROR'
    }
  }

  const onSubmit: SubmitHandler<App.Models.UserRegister> = async (value) => {
    try {
      const _value = {
        ...value,
        email: value.email,
        type: 'policy'
      }
      const res = await api.fetcher('post', '/customer/create', _value, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') }
      })

      if (res) {
        const request = async () => {
          webAuth.login(
            {
              realm: 'Username-Password-Authentication',
              email: value.email,
              password: value.password as string,
              audience: `https://${import.meta.env.VITE_APP_AUTH0_AUDIENCE}/api/v2/`,
              scope: 'openid profile email read:current_user',
              responseType: 'code',
              redirectUri: window.location.origin
            },
            function (err, authResult) {
              console.log(err, authResult)
            }
          )
        }
        return request()

        // localStorage.setItem('email-regis', value.email)
        // localStorage.setItem('is_reload', res.is_reload)
        // if (res.is_verify) {
        //   if (res.is_reload) {
        //     loginWithRedirect()
        //     return
        //   }

        //   const response = await api.fetcher('get', `/customer/info`)
        //   if (response) {
        //     updateUser(response.user_metadata)
        //   }

        //   const resUpdate = await updateIsLogin({ isLogin: true })
        //   localStorage.setItem('confirm_login_first', resUpdate.data.confirm_login_first)

        //   const type = localStorage.getItem('type')
        //   const listKey = ['site', 'type', 'is_reload']

        //   if (type == typeSite[1]) {
        //     await updateFirstLogin()
        //     const siteStore = `${localStorage.getItem('site')}`
        //     listKey.map((item) => localStorage.removeItem(item))
        //     window.location.href = siteStore
        //   } else if (!type || type == 'null') {
        //     console.log(1)

        //     fetchUser()
        //     navigate('/')
        //   } else {
        //     if (resUpdate.data.confirm_login_first) {
        //       localStorage.setItem('first_name', value.first_name)
        //       localStorage.setItem('family_name', value.family_name)

        //       navigate('/confirm')
        //     } else {
        //       if (
        //         localStorage.getItem('redirect') === 'true' &&
        //         localStorage.getItem('confirm_login_first') === 'true'
        //       ) {
        //         navigate('/')
        //         return
        //       }
        //       if (type === typeSite[2]) {
        //         handleGetAuthUser(
        //           localStorage.getItem('email-regis'),
        //           value.first_name,
        //           value.family_name
        //         )
        //       } else if (type === typeSite[3]) {
        //         window.location.href = `${localStorage.getItem('site')}`
        //         listKey.map((e) => localStorage.removeItem(e))
        //       }
        //     }
        //   }
        // } else {
        //   if (searchParams.get('policy')) {
        //     navigate('/otp?verify=true')
        //     return
        //   }

        //   setSignUpStep((prevActiveStep) => prevActiveStep + 1)
        // }
      }
    } catch (error) {
      toast.error(error.data.message)
    }
  }

  const handleBack = () => {
    setSignUpStep((prevActiveStep) => prevActiveStep - 1)
  }

  return <>{getStepContent(signUpStep)}</>
}

export { SignUp }
