import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { Loading } from 'components/Loader'

const Login: React.FC = () => {
  const { loginWithRedirect, isLoading, isAuthenticated } = useAuth0()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.get('site')) {
      localStorage.setItem('site', searchParams.get('site') as string)
      localStorage.setItem('type', searchParams.get('type') as string)
    }
  }, [searchParams])

  useEffect(() => {
    const checkAuthSession = async () => {
      if (!isLoading) {
        if (isAuthenticated) {
          return
        }

        loginWithRedirect()
      }
    }

    checkAuthSession()
  }, [isAuthenticated, isLoading])

  return (
    <div
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}
    >
      <Loading />
    </div>
  )
}

export { Login }
