import { Button, Container, Stack, Typography } from '@mui/material'
import { FormLayout } from 'components/Layouts'
import { useAuth0 } from '@auth0/auth0-react'
import './button.css'

const RegisterSuccess: React.FC = () => {
  const { loginWithRedirect } = useAuth0()
  return (
    <FormLayout title="ResonusIDに登録する" step={3}>
      <Container maxWidth="sm" sx={{ px: 0 }}>
        <Stack
          spacing={2}
          sx={{
            backgroundColor: '#FFFEFD',
            p: 2,
            borderRadius: '22px',
            boxShadow: '4px 4px 7px 0px #7775754D, -4px -4px 6px 0px #F7F5F3'
          }}
        >
          <Typography textAlign="center" fontSize={18}>
            アカウント登録が完了しました。
          </Typography>

          <Stack spacing={2} alignItems="center">
            <Button
              className="buttonVariant"
              onClick={() => loginWithRedirect()}
              sx={{ width: 150, height: 40, borderRadius: 23, color: '#111' }}
            >
              ログインへ
            </Button>
          </Stack>
        </Stack>
      </Container>
    </FormLayout>
  )
}

export { RegisterSuccess }
