import request from './request'

export type UpdateIsLoginType = {
    isLogin: boolean
}

const updateIsLogin = (values: UpdateIsLoginType) =>
    request.post('/shopify/customer/update/metadata', values, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') }
    })

export { updateIsLogin }
