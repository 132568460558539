import { Box, CircularProgress } from '@mui/material'
import React from 'react'

const Loading: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}
    >
      <CircularProgress sx={{ color: '#B2A99D' }} />
    </Box>
  )
}

export { Loading }
