import { useAuth0 } from '@auth0/auth0-react'
import { Box, Button, Container, Stack, Typography } from '@mui/material'
import './register/button.css'
import logo from 'assets/Logo.png'

const Error: React.FC = () => {
  const { loginWithRedirect } = useAuth0()

  const handleLogin = () => {
    loginWithRedirect()
  }

  return (
    <Box
      sx={{
        background: 'linear-gradient(168.64deg, #FDFCF9 -0.36%, #F9F7F5 99.47%)',
        minHeight: '100vh',
        py: '20px',
        px: { xs: '12px', md: '20px' }
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 24,
          paddingTop: '10px',
          paddingBottom: '10px'
        }}
      >
        <img src={logo} width={300} />
      </div>

      <Container maxWidth="sm" sx={{ px: 0 }}>
        <Stack
          spacing={2}
          sx={{
            backgroundColor: '#FFFEFD',
            p: 2,
            borderRadius: '22px',
            boxShadow: '4px 4px 7px 0px #7775754D, -4px -4px 6px 0px #F7F5F3'
          }}
        >
          <Typography variant="h6" textAlign="center" mb={3}>
            ログインに失敗しました
          </Typography>

          <Stack spacing={2} alignItems="center">
            <Button
              className="buttonVariant"
              onClick={() => handleLogin()}
              sx={{ width: 150, borderRadius: 23, color: '#111', height: 40 }}
            >
              ログインへ
            </Button>
          </Stack>
        </Stack>
      </Container>
    </Box>
  )
}

export { Error }
