import { useAuth0 } from '@auth0/auth0-react'
import { Box, Button, Container, Stack, Typography } from '@mui/material'
import { useAPI } from 'lib/api/api'
import React, { useContext, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AuthContext } from 'screens/AuthProvider'
import '../register/button.css'
import { Link } from 'react-router-dom'
import { typeSite } from 'lib/constant'
import { shopifyToken, updateFirstLogin } from 'lib/api'

const Home: React.FC = () => {
  const { logout, isLoading, isAuthenticated, user: userAuth0 } = useAuth0()
  const navigate = useNavigate()
  const api = useAPI()
  const { clear, user } = useContext(AuthContext)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (!searchParams.get('site')) {
      localStorage.removeItem('site')
      localStorage.removeItem('type')
      return
    }
    localStorage.setItem('site', searchParams.get('site') as string)
    localStorage.setItem('type', searchParams.get('type') as string)
  }, [searchParams])

  const updateLoginFirst = async () => {
    try {
      await updateFirstLogin()
    } catch (error) {
      console.log({ error })
    }
  }

  useEffect(() => {
    if (localStorage.getItem('confirm_login_first') === 'true') {
      updateLoginFirst()
    }
  }, [])

  useEffect(() => {
    if (!isLoading) {
      if (!isAuthenticated) {
        navigate('/login')
      }
    }
  }, [isAuthenticated, isLoading])

  const handleRedirect = async (type: string) => {
    if (type === typeSite[2]) {
      const urlShopify = `${import.meta.env.VITE_APP_SHOPIFY_URL}/account/login`
      const response = await shopifyToken({ email: user.email })
      if (response.data.isLink) {
        window.location.href = `${urlShopify}/multipass/${response.data.token}`
        return
      }

      window.location.href = `${urlShopify}/?accessToken=${response.data.token}&emailExists=${response.data.emailExists}&activeForm=true&emailAuth0=${user.email}&firstName=${user.first_name}&lastName=${user.family_name}`
    } else if (type === typeSite[3]) {
      window.location.href = `${import.meta.env.VITE_APP_KALTE_URL}`
    }
  }

  const handleLogout = async () => {
    try {
      const res = await api.fetcher('post', '/shopify/customer/update/metadata', {
        isLogin: false
      })
      if (res) {
        clear()
        logout()
        navigate('/login')
      }
    } catch (error) {
      console.log({ error })
    }
  }

  useEffect(() => {
    if (localStorage.getItem('confirm_login_first') === 'false') {
      localStorage.removeItem('redirect')
    }
  }, [])

  return (
    <Box>
      <Container
        maxWidth="xs"
        sx={{
          backgroundColor: '#FFFEFB',
          p: 2,
          borderRadius: '22px',
          boxShadow: '4px 4px 7px 0px #7775754D, -4px -4px 6px 0px #F7F5F3',
          mt: 3
        }}
      >
        <Stack spacing={2} mt={1}>
          <Typography>{user ? user.family_name + user.first_name + '様' : ''}</Typography>

          <Button
            className="buttonVariant"
            onClick={() => navigate('/information')}
            sx={{ height: 40, borderRadius: 23, color: '#111' }}
          >
            アカウント情報を変更
          </Button>
        </Stack>

        {userAuth0?.sub?.includes('auth0') && (
          <Stack spacing={2} mt={3}>
            <Button
              className="buttonVariant"
              onClick={() => navigate('/reset-password')}
              sx={{ height: 40, borderRadius: 23, color: '#111' }}
            >
              パスワードを変更
            </Button>
          </Stack>
        )}

        <Stack spacing={3} mt={3}>
          <Typography>サービスを利用する</Typography>

          <Button
            onClick={() => handleRedirect(typeSite[3])}
            className="buttonVariant"
            sx={{ height: 40, borderRadius: 23, color: '#111' }}
          >
            リゾナスフェイスクリニック東京予約
          </Button>

          <Button
            className="buttonVariant"
            onClick={() => handleRedirect(typeSite[2])}
            sx={{ height: 40, borderRadius: 23, color: '#111' }}
          >
            リゾナスオンラインストア
          </Button>

          <Button
            onClick={() => {
              window.location.href = `${import.meta.env.VITE_APP_KUROCO_URL}`
            }}
            className="buttonVariant"
            sx={{ height: 40, borderRadius: 23, color: '#111' }}
          >
            リゾナスビューティーコミュニティオアシス
          </Button>
        </Stack>
        <Stack spacing={2} mt={4}>
          <Link
            to="https://www.resonus-account.com/terms/"
            target="_blank"
            style={{ color: '#333333' }}
          >
            利用規約
          </Link>

          <Link
            to="https://www.resonus-account.com/privacy-policy/"
            target="_blank"
            style={{ color: '#333333' }}
          >
            プライバシーポリシー
          </Link>
        </Stack>
        <Button
          variant="outlined"
          className="button"
          onClick={handleLogout}
          sx={{
            height: 40,
            borderRadius: 23,
            color: '#989898',
            border: '1px solid #e0deda',
            ':hover': {
              border: '1px solid #e0deda'
            },
            mt: 2
          }}
        >
          ログアウト
        </Button>
      </Container>
    </Box>
  )
}

export { Home }
