import { Box, Divider, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

const NotFound: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}
    >
      <Stack spacing={2} alignItems="center">
        <Stack direction="row" spacing={3} sx={{ alignItems: 'center' }}>
          <Typography variant="h4">404</Typography>

          <Divider orientation="vertical" variant="middle" flexItem />

          <Typography>このページは見つかりませんでした。</Typography>
        </Stack>

        <Link to="" style={{ color: '#111' }}>
          ログインへ
        </Link>
      </Stack>
    </Box>
  )
}

export { NotFound }
