import { FormControlLabel, FormControlProps } from '@mui/material'
import { AddControlProps, InputControl } from 'components/components'
import { FieldValues, UseControllerProps, useController } from 'react-hook-form'
import './checkbox.css'

export type CheckBoxProps<T extends FieldValues> = UseControllerProps<T> &
  AddControlProps & {
    controlProps?: FormControlProps
    disabled?: boolean
  }

function CheckBox<T extends FieldValues>({
  name,
  label,
  control,
  controlProps,
  required,
  helperText,
  disabled
}: CheckBoxProps<T>) {
  const {
    field: { onChange, value },
    fieldState: { error }
  } = useController({ name, control })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked)
  }

  return (
    <InputControl
      fieldError={error}
      helperText={helperText}
      required={required}
      disabled={disabled}
      {...controlProps}
    >
      <FormControlLabel
        control={
          <label className="container">
            <input onChange={handleChange} checked={!!value} disabled={disabled} type="checkbox" />
            <div className="checkmark"></div>
          </label>
        }
        label={label as string}
        sx={{
          '& .MuiTypography-root': { fontSize: 15 },
          ...controlProps?.sx
        }}
      />
    </InputControl>
  )
}

export { CheckBox }
