import {
  Step,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  Stepper,
  styled
} from '@mui/material'
import { useSearchParams } from 'react-router-dom'
export type StepProp = {
  step: number
}

const steps = [1, 2, 3]

const stepsSignUp = [1, 2, 3]

const StepStyled = styled(Step)(() => ({
  '& span *': {
    padding: 0
  },
  padding: 0
}))

const StepConnectorStyled = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50%)',
    right: 'calc(-50%)'
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[300] : theme.palette.grey[100],
    borderTopWidth: 3,
    borderRadius: 1
  }
}))

const ColorlibStepIconRoot = styled('div', {
  shouldForwardProp: (prop) => prop !== 'ownerState'
})<{ ownerState?: any }>(({ theme, ownerState }) => ({
  backgroundColor: ownerState.completed ? theme.palette.primary.light : theme.palette.grey[100],
  zIndex: 1,
  width: 22,
  height: 22,
  fontSize: theme.typography.body2.fontSize,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#fff'
}))

const StepperStyled: React.FC<StepProp> = ({ step }) => {
  function ColorlibStepIcon(iconProps: any) {
    const { active, completed, className, icon } = iconProps

    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icon}
      </ColorlibStepIconRoot>
    )
  }
  const [searchParams] = useSearchParams()

  return (
    <Stepper
      sx={{ maxWidth: '350px', margin: 'auto' }}
      activeStep={step}
      connector={<StepConnectorStyled />}
    >
      {(searchParams.get('policy') === 'true' ? steps : stepsSignUp).map((label, index) => {
        return (
          <StepStyled key={index}>
            <StepLabel icon={label} StepIconComponent={ColorlibStepIcon} />
          </StepStyled>
        )
      })}
    </Stepper>
  )
}

export { StepperStyled }
