import React, { useContext, useEffect } from 'react'
import { Box, Button, Container, Stack, Typography } from '@mui/material'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Input } from 'components'
import { useAPI } from 'lib/api/api'
import { AuthContext } from 'screens/AuthProvider'
import { useNavigate } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import * as yup from 'yup'
import toast from 'react-hot-toast'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAuth0 } from '@auth0/auth0-react'
import { Loading } from 'components/Loader'
import '../register/button.css'

const validatePassword = (password: string) => {
  const upperCase = /[A-Z]/.test(password) ? 1 : 0
  const lowerCase = /[a-z]/.test(password) ? 1 : 0
  const number = /[0-9]/.test(password) ? 1 : 0
  const specialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password) ? 1 : 0
  const typesCount = upperCase + lowerCase + number + specialChar

  return typesCount >= 3
}

const commonSchema = {
  current_password: yup
    .string()
    .required('パスワードは必須項目です。')
    .test(
      'is-strong',
      'パスワードは8文字以上で、大文字・小文字・数字・記号のうち3種類以上を使用してください',
      (value) => validatePassword(value)
    ),
  new_password: yup
    .string()
    .required('パスワードは必須項目です。')
    .test(
      'is-strong',
      'パスワードは8文字以上で、大文字・小文字・数字・記号のうち3種類以上を使用してください',
      (value) => validatePassword(value)
    ),
  confirm_password: yup
    .string()
    .required('パスワードは必須項目です。')
    .oneOf([yup.ref('new_password')], 'パスワードが一致しません。')
}

const validate = yup.object({ ...commonSchema })

const ResetPassword: React.FC = () => {
  const { user } = useContext(AuthContext)
  const api = useAPI()
  const navigate = useNavigate()
  const { user: userAuth0, isLoading, isAuthenticated } = useAuth0()

  const checkValidate = () => {
    return validate
  }

  const {
    control,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm<App.Models.ResetPassword>({
    defaultValues: {
      current_password: '',
      new_password: '',
      confirm_password: ''
    },
    resolver: yupResolver(checkValidate()),
    mode: 'all'
  })

  const handleBack = () => {
    navigate('/')
    window.location.reload()
  }

  const handleBackButton = () => {
    navigate('/')
  }

  const onSubmit: SubmitHandler<App.Models.ResetPassword> = async (value) => {
    try {
      const _value = {
        ...value
      }

      await api.fetcher('post', `/customer/reset-password`, _value, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') }
      })

      toast.success('パスワードの変更が完了しました。', { duration: 4000 })
      setTimeout(handleBack, 3000)
    } catch (error) {
      toast.error(error.data.message)
    }
  }

  useEffect(() => {
    if (!isLoading) {
      if (!isAuthenticated) {
        return navigate('/login')
      }
    }
  }, [isLoading, isAuthenticated, userAuth0])

  if (!user) {
    return <Loading />
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Container
        maxWidth="home"
        sx={{
          backgroundColor: '#FFFEFB',
          p: 2,
          borderRadius: '22px',
          boxShadow: '4px 4px 7px 0px #7775754D, -4px -4px 6px 0px #F7F5F3',
          mt: 3
        }}
      >
        <Typography variant="h6" textAlign="center" mb={3}>
          パスワードの変更
        </Typography>

        <Stack spacing={1} direction={{ xs: 'column', md: 'column' }}>
          <Input
            control={control}
            name="current_password"
            label="現在のパスワード"
            fullWidth
            required
            placeholder="現在のパスワードを入力"
            type="password"
          />
          <Input
            control={control}
            name="new_password"
            label="新しいパスワード"
            fullWidth
            required
            placeholder="新しいパスワードを入力"
            type="password"
          />
          <Input
            control={control}
            name="confirm_password"
            label="新しいパスワードの確認"
            fullWidth
            required
            placeholder="新しいパスワードを入力"
            type="password"
          />
        </Stack>

        <Stack mt={4} spacing={2} direction="row" justifyContent="center">
          <Button
            onClick={() => handleBackButton()}
            variant="outlined"
            sx={{
              height: 40,
              borderRadius: 23,
              color: '#989898',
              border: '1px solid #e0deda',
              ':hover': {
                border: '1px solid #e0deda'
              },
              width: 100
            }}
            disabled={isSubmitting}
            className="button"
          >
            戻る
          </Button>

          <LoadingButton
            type="submit"
            loading={isSubmitting}
            sx={{ width: 150, borderRadius: 23, color: '#111', height: 40 }}
            className="buttonVariant"
          >
            変更
          </LoadingButton>
        </Stack>
      </Container>
    </Box>
  )
}

export { ResetPassword }
