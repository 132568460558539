import { Home } from 'screens/auth'
import React from 'react'
import { Information, ResetPassword } from 'screens/info'
import { Confirm } from 'screens/auth'
export type RouterProps = {
  index?: boolean
  path?: string
  element: React.ReactNode
}

export const router: RouterProps[] = [
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/information',
    element: <Information />
  },
  {
    path: 'confirm',
    element: <Confirm />
  },
  {
    path: '/reset-password',
    element: <ResetPassword />
  }
]
