import { Box, BoxProps, styled } from '@mui/material'
import { SxProps } from '@mui/system'
import { createRef } from 'react'
import { Header } from './Header'
import { Outlet } from 'react-router-dom'

export const drawerWidth = 240

export const mainRef = createRef<HTMLDivElement>()

export type LayoutProps = BoxProps<
  'div',
  {
    containerSx?: SxProps
    disabledGutters?: boolean
  }
>

const Layout: React.FC<LayoutProps> = ({ children, containerSx, disabledGutters, ...boxProps }) => {
  return (
    <Box
      sx={{
        paddingY: '20px',
        px: { xs: '12px', md: '20px' },
        background: 'linear-gradient(168.64deg, #FDFCF9 -0.36%, #F9F7F5 99.47%)'
      }}
    >
      <Header />
      <Box {...boxProps}>
        <Main ref={mainRef} disabledGutters={!disabledGutters} sx={containerSx}>
          <Outlet />
        </Main>
      </Box>
    </Box>
  )
}

const Main = styled('main', {
  shouldForwardProp: (prop) => !['open', 'disabledGutters'].includes(prop as string)
})<{
  open?: boolean
  disabledGutters?: boolean
}>(({ theme, open = true, disabledGutters = false }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),

  minHeight: 'calc(100vh - 160px)',
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  }),
  ...(!disabledGutters && {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4)
    }
  })
}))

export { Layout }
