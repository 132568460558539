import { Loading } from 'components/Loader'
import { useAPI } from 'lib/api/api'
import { Route, Routes, useSearchParams } from 'react-router-dom'
import { Login } from 'screens/auth'
import { NotFound } from 'screens/NotFound'
import { RequireAuth } from './RequireAuth'
import { Layout } from 'components/Layouts'
import { router } from './routes'
import { OTP, RegisterForm, RegisterSuccess } from 'screens/register'
import { Error } from 'screens/Error'
import { ErrorPage } from 'screens/ErrorPage'
import { SignUp } from 'screens/register/SignupForm'

export default function Router() {
  const { loading } = useAPI()
  const [searchParams] = useSearchParams()

  if (loading) {
    return <Loading />
  }

  return (
    <Routes>
      <Route path="/login" element={<Login />} />

      <Route path="/sign-up" element={searchParams.get('policy') ? <RegisterForm /> : <SignUp />} />

      <Route path="/otp" element={<OTP />} />

      <Route path="/register-success" element={<RegisterSuccess />} />

      <Route
        path="/"
        element={
          <RequireAuth>
            <Layout />
          </RequireAuth>
        }
      >
        {router.map((r, index) => (
          <Route path={r.path} element={r.element} key={index} />
        ))}
      </Route>

      <Route path="*" element={<NotFound />} />

      <Route path="/error-one-email" element={<Error />} />

      <Route path="/error" element={<ErrorPage />} />
    </Routes>
  )
}
