import { OutlinedInput, outlinedInputClasses, styled } from '@mui/material'

export type AdditionInputProps = {
  bgcolor?: string | false
  autoHeight?: boolean
}

const InputStyled = styled(OutlinedInput, {
  shouldForwardProp: (prop) => !['bgcolor', 'autoHeight'].includes(prop as string)
})<AdditionInputProps>(({ theme, autoHeight }) => ({
  background: 'linear-gradient(126.41deg, #FFFDFA 50.5%, #F5F2EE 84.18%)',
  boxShadow: 'inset 3px 3px 3px #98989840',
  transition: '300ms ease-in-out',
  borderRadius: 17,
  [`&.${outlinedInputClasses.disabled}`]: {
    backgroundColor: '#fff'
  },
  '&& .MuiInputBase-inputSizeSmall': {
    height: '1rem',
    fontSize: '0.75rem',
    padding: theme.spacing(1, 1.5)
  },
  '&&.MuiInputBase-sizeSmall': {
    '&& .MuiAutocomplete-input': {
      padding: '2px 4px 2px 6px'
    },
    height: autoHeight ? 'auto' : 32,
    minHeight: 32
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  // '& ::placeholder': {
  // color: theme.palette.grey[400]
  // },
  [`& .${outlinedInputClasses.input}`]: {
    // borderRadius: 5,
    // position: 'relative',
    // backgroundColor: bgcolor || 'none',
    fontSize: 16
  },
  height: autoHeight ? 'auto' : 46,
  minHeight: 46,
  '&& .MuiAutocomplete-input': {
    padding: '2px 4px 2px 6px'
  },
  '&.MuiInputBase-multiline': {
    height: 'auto'
  },
  '&.Mui-focused': {
    background: 'white',
    transform: 'scale(1.02)',
    boxShadow: '1px 1px 10px #969696, -1px -1px 10px #ffffff'
  }
}))

export { InputStyled }
