import { createTheme } from '@mui/material'
import { blue, brown, grey, red } from './colors'

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true
    sm: true
    md: true
    home: true
    lg: true
    xl: true
    modal: true
  }
}

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: brown[100],
      light: brown[100]
    },
    grey: {
      300: grey[300],
      200: grey[200],
      100: grey[100],
      50: grey[50]
    },
    info: {
      main: blue[600]
    },
    error: {
      main: red[600]
    }
  },
  mixins: {
    toolbar: {
      minHeight: 80,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: brown[100]
    }
  },
  typography: {
    fontFamily: 'HeiseiMinStd',
    h5: {
      fontWeight: 400,
      fontSize: '1.75rem' // 24px
    },
    h6: {
      fontWeight: 400,
      fontSize: '1.5rem' // 16px
    },
    subtitle1: {
      fontWeight: 100
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 940,
      home: 800,
      lg: 1100,
      xl: 1440,
      modal: 1300
    }
  },
  shape: {
    borderRadius: 5
  },
  components: {
    // MuiButton: {
    //   styleOverrides: {
    //     text: {
    //       padding: 0,
    //       '&:hover': {
    //         backgroundColor: 'transparent'
    //       }
    //     },
    //     root: {
    //       '&:hover': {
    //         opacity: 0.7
    //       },
    //       '@media (max-width:600px)': {
    //         fontSize: '1rem'
    //       },
    //       textTransform: 'none',
    //       color: 'white'
    //     },
    //     outlined: {
    //       color: brown[100],
    //       backgroundColor: 'white'
    //     },
    //     textInherit: {
    //       '@media (max-width:600px)': {
    //         lineHeight: '1rem'
    //       }
    //     },
    //     containedPrimary: {
    //       '&:hover': {
    //         backgroundColor: brown[100]
    //       }
    //     },
    //     endIcon: {
    //       marginLeft: 0
    //     }
    //   },
    //   defaultProps: {
    //     disableRipple: true,
    //     disableElevation: true
    //   }
    // },
    MuiTypography: {
      styleOverrides: {}
    },
    // MuiSvgIcon: {
    //   styleOverrides: {
    //     root: {
    //       fill: brown[100]
    //     },
    //     colorPrimary: {
    //       fill: grey[300]
    //     },
    //     colorAction: {
    //       fill: 'white'
    //     }
    //   }
    // },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: grey[100]
        }
      }
    },
    MuiPaper: {
      defaultProps: {
        elevation: 6
      }
    }
  }
})

// Responsive Typography
defaultTheme.typography.h5 = {
  ...defaultTheme.typography.h5,
  [defaultTheme.breakpoints.down('sm')]: {
    fontSize: '1.5rem'
  }
}

defaultTheme.typography.h6 = {
  ...defaultTheme.typography.h6,
  [defaultTheme.breakpoints.down('sm')]: {
    fontSize: '1rem'
  }
}

// Responsive Toolbar
defaultTheme.mixins.toolbar = {
  ...defaultTheme.mixins.toolbar,
  [defaultTheme.breakpoints.down('sm')]: {
    height: 60,
    minHeight: 60
  }
}

defaultTheme.shadows[1] = '0 0 2px rgb(0 0 0 / 10%)'
defaultTheme.shadows[2] = '0 0 10px -4px rgba(0, 0, 0, 0.19)'
defaultTheme.shadows[3] = '0 0 8px -2px rgba(0, 0, 0, 0.25)'

// defaultTheme.typography.fontFamily = ['"Heisei Mincho StdN"', 'sans-serif'].join(', ')

export { defaultTheme }
