import { CssBaseline, ThemeProvider } from '@mui/material'
import { defaultTheme } from './styles'
import Router from 'routers/Router'
import { AuthProvider } from 'screens/AuthProvider'
import { Toaster } from 'react-hot-toast'
import { Auth0Provider } from '@auth0/auth0-react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded'

function App() {
  return (
    <Auth0Provider
      domain={import.meta.env.VITE_APP_AUTH0_DOMAIN}
      clientId={import.meta.env.VITE_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: `https://${import.meta.env.VITE_APP_AUTH0_AUDIENCE}/api/v2/`,
        scope: 'openid profile email read:current_user'
      }}
    >
      <AuthProvider>
        <ThemeProvider theme={defaultTheme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CssBaseline />
            <Toaster
              toastOptions={{
                error: {
                  style: {
                    border: '2px solid #FF4040',
                    borderRadius: '30px',
                    background: '#fff',
                    color: '#000',
                    fontWeight: 500
                  },
                  icon: (
                    <div
                      style={{
                        backgroundColor: '#FF4040',
                        borderRadius: '50%',
                        width: '30px',
                        height: '26px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <PriorityHighRoundedIcon style={{ color: '#fff', fontSize: '20px' }} />
                    </div>
                  )
                }
              }}
            />
            <Router />
          </LocalizationProvider>
        </ThemeProvider>
      </AuthProvider>
    </Auth0Provider>
  )
}

export default App
