import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Button, Container, Stack, Typography } from '@mui/material'
import { Input } from 'components'
import { FormLayout } from 'components/Layouts'
import { useAPI } from 'lib/api/api'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import * as yup from 'yup'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { typeSite } from 'lib/constant'
// import { updateIsLogin } from 'lib/api'
import { AuthContext, handleGetAuthUser } from 'screens/AuthProvider'
import { useAuth0 } from '@auth0/auth0-react'
import './button.css'

export type OTPType = {
  otp: string
}

const validate = yup.object({
  otp: yup
    .string()
    .typeError('認証コードは必須フィールドです。')
    .required('認証コードは必須フィールドです。')
})

type Props = {
  handleBack?: () => void
}

const OTP: React.FC<Props> = ({ handleBack }) => {
  const { logout } = useAuth0()
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    resetField,
    setValue
  } = useForm<OTPType>({
    defaultValues: {
      otp: undefined
    },
    resolver: yupResolver(validate),
    mode: 'all'
  })

  const [searchParams] = useSearchParams()
  const [loadingResend, setLoadingResend] = useState<boolean>(false)
  const { loginWithRedirect } = useAuth0()
  const { updateUser } = useContext(AuthContext)
  const [emailRegis, setEmailRegis] = useState<string>()

  useEffect(() => {
    if (searchParams.get('email')) {
      localStorage.setItem('email-regis', searchParams.get('email') as string)
    }
  }, [searchParams])

  useEffect(() => {
    if (localStorage.getItem('email-regis')) {
      setEmailRegis(localStorage.getItem('email-regis') as string)
    }
  }, [])

  const api = useAPI()
  const navigate = useNavigate()
  const token = localStorage.getItem('accessToken')

  const onSubmit: SubmitHandler<OTPType> = async (value) => {
    try {
      const _value = {
        ...value,
        email: emailRegis ?? localStorage.getItem('email-regis')
      }
      const res = await api.fetcher('post', '/customer/otp/verify', _value)
      if (res) {
        resetField('otp')
        if (searchParams.get('verify')) {
          if (localStorage.getItem('is_reload') === 'true') {
            loginWithRedirect()
          } else if (token) {
            localStorage.setItem(
              'email-policy',
              emailRegis ?? (localStorage.getItem('email-regis') as string)
            )
            navigate('/sign-up?policy=false')
          } else {
            const res = await api.fetcher('get', `/customer/info`)
            if (res) {
              updateUser(res.user_metadata)
            }

            // await updateIsLogin({ email: localStorage.getItem('email-regis'), isLogin: true })

            const type = localStorage.getItem('type')
            const listKey = ['site', 'type', 'is_reload']

            if (type == typeSite[2]) {
              handleGetAuthUser(localStorage.getItem('email-regis'))
            } else if (type === typeSite[1]) {
              listKey.map((item) => localStorage.removeItem(item))
              window.location.href = `${import.meta.env.VITE_APP_KUROCO_URL}`
            } else if (type === typeSite[3]) {
              listKey.map((item) => localStorage.removeItem(item))
              window.location.href = `${import.meta.env.VITE_APP_KALTE_URL}`
            } else {
              navigate('/')
            }
            localStorage.removeItem('email-policy')
            localStorage.removeItem('email-regis')
            localStorage.removeItem('email-policy')
          }
        } else {
          navigate(`/register-success`)
          localStorage.removeItem('is_reload')
          localStorage.removeItem('email-regis')
          localStorage.removeItem('email-policy')
        }
      }
    } catch (error) {
      toast.error(error.data.message)
    }
  }

  const handleResendVerify = async () => {
    try {
      setLoadingResend(true)
      const res = await api.fetcher('post', '/customer/otp/resend', {
        email: emailRegis ?? localStorage.getItem('email-regis')
      })

      if (res) {
        toast.success('認証コードを送信しました。ご確認ください。', {
          style: {
            border: '1px solid #713200',
            padding: '16px',
            color: '#713200'
          },
          iconTheme: {
            primary: '#713200',
            secondary: '#FFFAEE'
          }
        })
        setLoadingResend(false)
      }
    } catch (error) {
      setLoadingResend(false)
      toast.error(error.data.message)
      setTimeout(loginWithRedirect, 3000)
    }
  }

  const handleNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, '')
    const numericValue = inputValue ? inputValue : ''

    setValue('otp', numericValue)
  }

  return (
    <FormLayout title="RESONUS ACCOUNTに登録する" step={2}>
      <Container maxWidth="sm" sx={{ px: 0 }}>
        <Stack
          spacing={2}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            backgroundColor: '#FFFEFB',
            p: 2,
            borderRadius: '22px',
            boxShadow: '4px 4px 7px 0px #7775754D, -4px -4px 6px 0px #F7F5F3'
          }}
        >
          <Typography textAlign="center" fontSize={18}>
            {emailRegis ?? localStorage.getItem('email-regis')}
            に送られた6桁のコードを入力してください
          </Typography>

          {/*<Typography textAlign="center" fontSize={14}>*/}
          {/*  メールが届かない場合は認証コードを再送信してください。*/}
          {/*</Typography>*/}

          <Stack spacing={1}>
            <Input
              control={control}
              name="otp"
              label="認証コード"
              required
              fullWidth
              placeholder="認証コードを入力"
              controlProps={{
                onChange: handleNumber
              }}
            />
            <Typography
              variant="caption"
              color="#989898"
              sx={{ '&.MuiTypography-root': { marginLeft: 1 } }}
            >
              ※ メールが届かない場合、
              <br />
              1. メールアドレスが正しいかどうか再確認してください。
              <br />
              2. 迷惑メールフォルダを確認してください。それでも届かない場合は、
              noreply@resonus-account.comからのメールが受信できるように、迷惑メールフィルタの設定を見直してください。
              <br />
              3. 認証コードを再送信してください。
            </Typography>
          </Stack>

          <Stack alignItems="center">
            <Stack direction="row" spacing={3} mt={2}>
              {handleBack && (
                <Button
                  sx={{
                    height: 40,
                    borderRadius: 23,
                    color: '#989898',
                    border: '1px solid #e0deda',
                    ':hover': {
                      border: '1px solid #e0deda'
                    },
                    width: 80
                  }}
                  onClick={handleBack}
                  className="button"
                >
                  戻る
                </Button>
              )}

              <LoadingButton
                className="buttonVariant"
                sx={{ width: 150, height: 40, borderRadius: 23, color: '#111' }}
                type="submit"
                loading={isSubmitting}
              >
                登録する
              </LoadingButton>

              <LoadingButton
                variant="outlined"
                className="button"
                onClick={handleResendVerify}
                loading={loadingResend}
                sx={{
                  height: 40,
                  borderRadius: 23,
                  color: '#989898',
                  border: '1px solid #e0deda',
                  ':hover': {
                    border: '1px solid #e0deda'
                  }
                }}
              >
                認証コードを再送
              </LoadingButton>
            </Stack>

            <Typography
              style={{
                fontSize: 12,
                color: '#111',
                justifyContent: 'center',
                display: 'flex',
                marginTop: 16,
                textDecoration: 'underline',
                cursor: 'pointer'
              }}
              onClick={() => logout()}
            >
              ログインに戻る
            </Typography>
          </Stack>
        </Stack>
      </Container>
    </FormLayout>
  )
}

export { OTP }
