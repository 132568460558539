import CloseIcon from '@mui/icons-material/Close'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
  Autocomplete,
  FormControlProps,
  FormLabel,
  InputAdornment,
  Stack,
  Typography
} from '@mui/material'
import { Box, BoxProps } from '@mui/system'
import { AddControlProps, FormHelper, InputStyled } from 'components/components'
import { isValid } from 'date-fns'
import { addZero, getDaysInMonth } from 'lib/constant'
import { useEffect, useMemo, useState } from 'react'
import { FieldError, FieldValues, UseControllerProps, useController } from 'react-hook-form'
import ranger from 'lodash/rangeRight'

export type DatePickerSeparatorProps<T extends FieldValues> = UseControllerProps<T> &
  AddControlProps & {
    controlProps?: FormControlProps
    fullWidth?: boolean
    containerProps?: BoxProps
    splitString?: string
  }

export type ValidateErrorMessage = {
  key: string
  values: { path: string }
}

const monthOptions = [...new Array(12)].map((_, index) => addZero(index + 1))
const yearRanger = [1900, new Date().getFullYear()]
const yearOptions = ranger(yearRanger[0], yearRanger[1] + 1).map(String)
const defaultDayOptions = [...new Array(31)].map((_, index) => addZero(index + 1))
const isAndroid = /Android/i.test(navigator.userAgent)

function DatePickerSeparator<T extends FieldValues>({
  name,
  control,
  defaultValue,
  label,
  helperText,
  containerProps,
  required,
  splitString = '/'
}: DatePickerSeparatorProps<T>) {
  const {
    field: { onChange, value },
    fieldState: { error: fieldError }
  } = useController({ name, control, defaultValue })

  const [year, setYear] = useState<string | null>(null)
  const [month, setMonth] = useState<string | null>(null)
  const [date, setDate] = useState<string | null>(null)

  const handleChangeValue = (newValue: string, type: 'year' | 'month' | 'date') => {
    let d: string
    switch (type) {
      case 'year':
        setYear(newValue)
        d = newValue + splitString + month + splitString + date
        break
      case 'month':
        setMonth(newValue)
        d = year + splitString + newValue + splitString + date
        break
      default:
        setDate(newValue)
        d = year + splitString + month + splitString + newValue
        break
    }
    onChange(d)
  }

  useEffect(() => {
    if (!value || !isValid(new Date(value as Date))) return
    const date = new Date(value)
    const formattedDate = date
      .toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' })
      .replace(/\//g, '-')

    const separator = formattedDate.includes('/') ? '/' : '-'
    const d = (formattedDate as string).split(separator)
    setYear(d[2])
    setMonth(d[1])
    setDate(d[0])
  }, [value, splitString])

  const dateOptions = useMemo(() => {
    const dates =
      year && month
        ? getDaysInMonth(Number(year), Number(month) - 1).map(addZero)
        : defaultDayOptions

    if (!dates.includes(date as string) && date !== null) {
      setDate(dates[dates.length - 1])
    }

    return dates
  }, [date, month, year])

  const renderError = () => {
    const errorType = typeof fieldError

    if (errorType === 'boolean') {
      return helperText
    }

    if (typeof (fieldError as FieldError)?.message === 'object') {
      return ((fieldError as FieldError)?.message as unknown as ValidateErrorMessage).values.path
    }

    if (typeof (fieldError as FieldError)?.message === 'string') {
      return (fieldError as FieldError)?.message
    }
  }

  return (
    <Box {...containerProps}>
      {label && (
        <FormLabel error={fieldError ? true : false} sx={{ color: '#252422' }}>
          {label}
          {required && (
            <Typography ml={0.5} variant="caption" color="error.main">
              *
            </Typography>
          )}
        </FormLabel>
      )}
      <Stack direction="row" mt={0.5} spacing={1}>
        <Autocomplete
          options={yearOptions}
          disableClearable
          value={year as string}
          onChange={(_, newValue) => handleChangeValue(newValue, 'year')}
          sx={{
            minWidth: 100,
            '&& .MuiOutlinedInput-root': {
              py: 0,
              pr: 3
            }
          }}
          renderInput={(params) => (
            <InputStyled
              fullWidth
              error={fieldError ? true : false}
              {...params.InputProps}
              inputProps={{ ...params.inputProps, readOnly: isAndroid }}
              placeholder="年"
              endAdornment={
                <InputAdornment position="end">
                  {typeof year === 'string' ? '年' : undefined}
                  {params.InputProps.endAdornment}
                </InputAdornment>
              }
            />
          )}
          popupIcon={<KeyboardArrowDownIcon sx={{ fontSize: 16 }} />}
          clearIcon={<CloseIcon sx={{ fontSize: 16 }} />}
          noOptionsText="データなし"
        />

        <Autocomplete
          options={monthOptions}
          disableClearable
          value={month as string}
          onChange={(_, newValue) => handleChangeValue(newValue, 'month')}
          sx={{
            minWidth: 100,
            '&& .MuiOutlinedInput-root': {
              py: 0,
              pr: 3
            }
          }}
          renderInput={(params) => (
            <InputStyled
              fullWidth
              error={fieldError ? true : false}
              {...params.InputProps}
              inputProps={{ ...params.inputProps, readOnly: isAndroid }}
              placeholder="月"
              endAdornment={
                <InputAdornment position="end">
                  {typeof month === 'string' ? '月' : undefined}
                  {params.InputProps.endAdornment}
                </InputAdornment>
              }
            />
          )}
          popupIcon={<KeyboardArrowDownIcon sx={{ fontSize: 16 }} />}
          clearIcon={<CloseIcon sx={{ fontSize: 16 }} />}
          noOptionsText="データなし"
        />

        <Autocomplete
          options={dateOptions}
          disableClearable
          value={date as string}
          onChange={(_, newValue) => handleChangeValue(newValue, 'date')}
          sx={{
            minWidth: 100,
            '&& .MuiOutlinedInput-root': {
              py: 0,
              pr: 3
            }
          }}
          renderInput={(params) => (
            <InputStyled
              fullWidth
              error={fieldError ? true : false}
              {...params.InputProps}
              inputProps={{ ...params.inputProps, readOnly: isAndroid }}
              placeholder="日"
              endAdornment={
                <InputAdornment position="end">
                  {typeof date === 'string' ? '日' : undefined}
                  {params.InputProps.endAdornment}
                </InputAdornment>
              }
            />
          )}
          popupIcon={<KeyboardArrowDownIcon sx={{ fontSize: 16 }} />}
          clearIcon={<CloseIcon sx={{ fontSize: 16 }} />}
          noOptionsText="データなし"
        />
      </Stack>
      {helperText && <FormHelper error={false}>{helperText}</FormHelper>}

      {!!fieldError && <FormHelper error>{renderError()}</FormHelper>}
    </Box>
  )
}

export { DatePickerSeparator }
