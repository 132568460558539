const grey = {
  50: '#fafafa',
  100: '#C7C7C7',
  200: '#333333',
  300: '#000000'
}

const brown = {
  50: '#C1BA8F',
  100: '#B2A99D'
}

const red = {
  600: '#ff0000'
}

const blue = {
  600: '#3189f8'
}

export { grey, brown, red, blue }
