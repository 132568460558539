import { Box, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

const ErrorPage: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}
    >
      <Stack spacing={1} alignItems="center">
        <Stack spacing={2} sx={{ alignItems: 'center' }}>
          <Typography variant="h5">エラーが発生しました</Typography>

          <Typography fontSize={18}>再度アクセスしてください</Typography>
        </Stack>

        <Link to="/" style={{ color: '#111', fontSize: 18 }}>
          ホームに戻る
        </Link>
      </Stack>
    </Box>
  )
}

export { ErrorPage }
