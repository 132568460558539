import { useAuth0 } from '@auth0/auth0-react'
import { Loading } from 'components/Loader'
import { shopifyToken, updateFirstLogin, updateIsLogin } from 'lib/api'
import { useAPI } from 'lib/api/api'
import { typeSite } from 'lib/constant'
import { valueAuth } from 'lib/types'
import { createContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const defaultValue: valueAuth = {
  user: null,
  updateUser: () => {},
  clear: () => {},
  revalidate: () => {},
  fetchUser: () => {}
}

export const AuthContext = createContext<valueAuth>(defaultValue)

export const handleGetAuthUser = async (email: any, family_name?: string, first_name?: string) => {
  const response = await shopifyToken({ email: email })
  const siteStore = localStorage.getItem('site')
  const listKey = ['site', 'type', 'is_reload', 'first_name', 'family_name']

  if (response.data.isLink) {
    window.location.href = `${siteStore}/account/login/multipass/${response.data.token}`
    listKey.map((item) => localStorage.removeItem(item))
    return
  }

  window.location.href = `${siteStore}/account/login/?accessToken=${response.data.token}&activeForm=true&emailAuth0=${email}&firstName=${first_name}&lastName=${family_name}&emailExists=${response.data.emailExists}`
  listKey.map((item) => localStorage.removeItem(item))
}

const AuthProvider = (props?: any) => {
  const [user, setUser] = useState<any>(null)
  const [loaded, setLoaded] = useState(false)
  const api = useAPI()
  const navigate = useNavigate()
  const { isLoading, isAuthenticated, user: userAuth0, getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    if (window.location.pathname === '/information') {
      sessionStorage.setItem('informationRoute', 'true')
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (isLoading || !isAuthenticated || !userAuth0) {
        localStorage.removeItem('accessToken')
        return
      }
      const domain = import.meta.env.VITE_APP_AUTH0_DOMAIN
      try {
        const accessToken = await getAccessTokenSilently()
        localStorage.setItem('accessToken', accessToken)

        const userDetailsByIdUrl = `https://${domain}/api/v2/users/${userAuth0.sub}`
        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: { Authorization: `Bearer ${accessToken}` }
        })
        const { user_metadata, app_metadata } = await metadataResponse.json()
        setUser(user_metadata)

        if (app_metadata === undefined || (!app_metadata.is_policy && app_metadata.email_verify)) {
          localStorage.setItem('email-policy', userAuth0?.email ?? '')
          if (userAuth0.sub?.includes('auth0')) {
            navigate('/sign-up?policy=false')
            return
          }
          navigate('/sign-up?policy=true')
        } else {
          if (!app_metadata.email_verify) {
            localStorage.setItem('email-regis', user_metadata.email)
            navigate('/otp?verify=true')
            return
          }

          const resLogin = await updateIsLogin({ isLogin: true })
          localStorage.setItem('confirm_login_first', resLogin.data.confirm_login_first)

          const type = localStorage.getItem('type')
          const informationRoute = sessionStorage.getItem('informationRoute')
          const listKey = ['site', 'type']

          if (informationRoute === 'true' || window.location.pathname === '/information') {
            navigate('/information')
            sessionStorage.removeItem('informationRoute')
            return
          }

          if (type === typeSite[1]) {
            await updateFirstLogin()
            window.location.href = `${localStorage.getItem('site')}`
            listKey.map((e) => localStorage.removeItem(e))
          } else if (!type || type === 'null') {
            const routerArr = [
              '/',
              '/information',
              '/login',
              '/sign-up',
              '/otp',
              '/register-success',
              '/error-one-email'
            ]
            if (!routerArr.includes(window.location.pathname)) {
              return
            }

            navigate('/')
            listKey.map((el) => localStorage.removeItem(el))
          } else {
            if (resLogin.data.confirm_login_first === true) {
              localStorage.setItem('email-regis', user_metadata.email)
              localStorage.setItem('first_name', user_metadata.first_name)
              localStorage.setItem('family_name', user_metadata.family_name)

              navigate('/confirm')
            } else {
              if (
                localStorage.getItem('redirect') === 'true' &&
                localStorage.getItem('confirm_login_first') === 'true'
              ) {
                navigate('/')
                return
              }

              if (type === typeSite[2]) {
                handleGetAuthUser(
                  user_metadata.email,
                  user_metadata.family_name,
                  user_metadata.first_name
                )
              } else if (type === typeSite[3]) {
                window.location.href = `${localStorage.getItem('site')}`
                listKey.map((e) => localStorage.removeItem(e))
              }
            }
          }
        }
      } catch (error) {
        console.error(error.message)
      }
      setLoaded(true)
    }

    fetchData()
  }, [isLoading, isAuthenticated, userAuth0])

  const updateUser = async (data: any) => {
    setUser(data)
  }

  const clear = async () => {
    const listKey = [
      'type',
      'site',
      'token',
      'site',
      'email',
      'email-regis',
      'email-policy',
      'accessToken',
      'is_reload',
      'redirect',
      'first_name',
      'family_name',
      'confirm_login_first'
    ]
    sessionStorage.removeItem('sub')
    listKey.map((item) => localStorage.removeItem(item))
    setUser(null)
  }

  const revalidate = async () => {
    getUserMetadata()
  }

  const fetchUser = async () => {
    getUserMetadata()
  }

  const value: valueAuth = {
    user,
    updateUser,
    clear,
    revalidate,
    fetchUser
  }
  const getUserMetadata = async () => {
    const accessToken = localStorage.getItem('accessToken')
    if (accessToken) {
      try {
        const res = await api.fetcher('get', `/customer/info`)
        if (res) {
          setUser(res.user_metadata)
        }
      } catch (error) {
        setUser(null)
        sessionStorage.removeItem('sub')
      }
    } else {
      setUser(null)
    }
  }

  useEffect(() => {
    getUserMetadata()
    setLoaded(true)
  }, [])

  if (!loaded) {
    return <Loading />
  }

  return <AuthContext.Provider value={value as valueAuth} {...props} />
}

export { AuthProvider }
