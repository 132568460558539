import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import { useAPI } from 'lib/api/api'
import { useContext, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import './button.css'
import { AccountForm, AddressForm, InfoForm } from '.'
import { useAuth0 } from '@auth0/auth0-react'
import { AuthContext, handleGetAuthUser } from 'screens/AuthProvider'
import { updateFirstLogin, updateIsLogin } from 'lib/api'
import { countryOption, typeSite } from 'lib/constant'

const emojiRegex =
  /^[^\u{1F600}-\u{1F6FF}\u{1F300}-\u{1F5FF}\u{1F900}-\u{1F9FF}\u{1F1E6}-\u{1F1FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]*$/u
const katakanaRegex = /^[\u30A0-\u30FF]+$/;

const schemaAccount = {
  email: yup
    .string()
    .required('メールアドレスは必須項目です。')
    .email('メールアドレスが無効です。')
    .matches(
      /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/,
      'メールアドレスが無効です。'
    )
}

const schemaInfo = {
  first_name: yup
    .string()
    .required('名は必須項目です。')
    .matches(emojiRegex, '不正な文字が含まれています。'),
  first_name_kana: yup
    .string()
    .required('メイ（カナ）は必須項目です。')
    .matches(katakanaRegex, 'カタカナで入力してください。'),
  family_name: yup
    .string()
    .required('姓は必須項目です。')
    .matches(emojiRegex, '不正な文字が含まれています。'),
  family_name_kana: yup
    .string()
    .required('セイ（カナ）は必須項目です。')
    .matches(katakanaRegex, 'カタカナで入力してください。'),
  gender: yup
    .number()
    .typeError('性別は有効である必要があります。')
    .required('性別は必須項目です。'),
  dob: yup
    .date()
    .typeError('生年月日を入力してください。')
    .required('生年月日は必須項目です。')
    .max(new Date(), '現在の日付以前の日付を選択してください。'),
  tel: yup
    .string()
    .required('電話番号は必須項目です。')
    .matches(
      /^(?=[\d-]{7,14}$)(?=(?:[^-]*-){2}[^-]*$)[\d-]+$/,
      '電話番号の形式が正しくありません。ハイフンを2つ含み、7文字以上14文字以下で入力してください。'
    )
}

const schemaAddress = {
  is_policy: yup
    .boolean()
    .oneOf([true], 'ご登録いただくには、同意するにチェックしてください。')
    .required('ご登録いただくには、同意するにチェックしてください。'),
  city: yup
    .string()
    .required('市区町村は必須項目です。')
    .matches(emojiRegex, '不正な文字が含まれています'),
  address1: yup.string().matches(emojiRegex, '不正な文字が含まれています。'),
  address2: yup.string().matches(emojiRegex, '不正な文字が含まれています。')
}

const validateNoPolicy = [
  yup.object({
    ...schemaAccount
  }),
  yup.object({
    ...schemaInfo
  }),
  yup.object({
    ...schemaAddress
  })
]

const validateNoPolicyAndPostalCode = [
  yup.object({
    ...schemaAccount
  }),
  yup.object({
    ...schemaInfo
  }),
  yup.object({
    ...schemaAddress,
    postal_code: yup
      .string()
      .required('郵便番号は必須項目です。')
      .matches(/^([0-9]{7})?$/, '郵便番号の形式は正しくありません。'),
    prefecture: yup
      .number()
      .required('都道府県は必須フィールドです。')
      .typeError('都道府県は必須フィールドです。')
  })
]

const validate = [
  yup.object({
    ...schemaAccount,
    password: yup
      .string()
      .required('パスワードは必須項目です。')
      .min(8, 'パスワードは8文字以上でなければなりません')
      .matches(
        /^(?=.{8,})((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*/,
        'パスワードは8文字以上で、大文字・小文字・数字・記号のうち3種類以上を使用してください'
      ),
    confirm_password: yup
      .string()
      .required('パスワードの確認は必須項目です。')
      .oneOf([yup.ref('password'), ''], 'パスワードが一致しません。')
  }),
  yup.object({
    ...schemaInfo
  }),
  yup.object({
    ...schemaAddress
  })
]
const validatePostalCode = [
  yup.object({
    ...schemaAccount,
    password: yup
      .string()
      .required('パスワードは必須フィールドです。')
      .min(8, 'パスワードは8文字以上でなければなりません')
      .matches(
        /^(?=.{8,})((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*/,
        'パスワードは8文字以上で、大文字・小文字・数字・記号のうち3種類以上を使用してください'
      ),
    confirm_password: yup
      .string()
      .required('パスワードの確認は必須フィールドです。')
      .oneOf([yup.ref('password'), ''], 'パスワードが一致しません。')
  }),
  yup.object({
    ...schemaInfo
  }),
  yup.object({
    ...schemaAddress,
    postal_code: yup
      .string()
      .required('郵便番号は必須項目です。')
      .matches(/^([0-9]{7})?$/, '郵便番号の形式は正しくありません。'),
    prefecture: yup
      .number()
      .required('都道府県は必須フィールドです。')
      .typeError('都道府県は必須フィールドです。')
  })
]

const RegisterForm: React.FC = () => {
  const api = useAPI()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [signUpStep, setSignUpStep] = useState<number>(
    searchParams.get('policy') === 'false' ? 1 : 0
  )
  const { loginWithRedirect } = useAuth0()
  const { updateUser, fetchUser } = useContext(AuthContext)
  const [loadingCheck, setLoadingCheck] = useState<boolean>(false)
  const [country, setCountry] = useState<number>(1)

  const checkValidate = (policy: string, country: number) => {
    if (policy && country === countryOption.JAPAN) {
      return validateNoPolicyAndPostalCode[signUpStep]
    } else if (policy) {
      return validateNoPolicy[signUpStep]
    } else if (country === countryOption.JAPAN) {
      return validatePostalCode[signUpStep]
    } else {
      return validate[signUpStep]
    }
  }

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    watch,
    formState: { isSubmitting, errors },
    trigger
  } = useForm<any>({
    defaultValues: {
      first_name: '',
      family_name: '',
      first_name_kana: '',
      family_name_kana: '',
      gender: undefined,
      dob: '',
      tel: '',
      prefecture: undefined,
      postal_code: '',
      city: '',
      address1: '',
      email: '',
      password: '',
      is_policy: false
    },
    resolver: yupResolver(checkValidate(searchParams.get('policy') as string, country) as any),
    mode: 'all',
    shouldUnregister: false
  })

  const onSubmit: SubmitHandler<App.Models.UserRegister> = async (value) => {
    try {
      const _value = {
        ...value,
        email: value.email,
        type: 'policy',
        dob: dayjs(value.dob).format('YYYY-MM-DD'),
        prefecture: country === countryOption.JAPAN ? value.prefecture : 99
      }
      const res = await api.fetcher('post', '/customer/update/metadata', _value, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') }
      })

      if (res) {
        localStorage.setItem('email-regis', value.email)
        localStorage.setItem('is_reload', res.is_reload)
        if (res.is_verify) {
          if (res.is_reload) {
            loginWithRedirect()
            return
          }

          const response = await api.fetcher('get', `/customer/info`)
          if (response) {
            localStorage.removeItem('email-regis')
            localStorage.removeItem('is_reload')
            localStorage.removeItem('email-policy')
            updateUser(response.user_metadata)
          }

          const resUpdate = await updateIsLogin({ isLogin: true })
          localStorage.setItem('confirm_login_first', resUpdate.data.confirm_login_first)

          const type = localStorage.getItem('type')
          const listKey = ['site', 'type', 'is_reload']

          if (type == typeSite[1]) {
            await updateFirstLogin()
            const siteStore = `${localStorage.getItem('site')}`
            listKey.map((item) => localStorage.removeItem(item))
            window.location.href = siteStore
          } else if (!type || type === 'null') {
            fetchUser()
            navigate('/')
          } else {
            if (resUpdate.data.confirm_login_first) {
              localStorage.setItem('first_name', value.first_name)
              localStorage.setItem('family_name', value.family_name)

              navigate('/confirm')
            } else {
              if (
                localStorage.getItem('redirect') === 'true' &&
                localStorage.getItem('confirm_login_first') === 'true'
              ) {
                navigate('/')
                return
              }
              if (type === typeSite[2]) {
                handleGetAuthUser(
                  localStorage.getItem('email-regis'),
                  value.first_name,
                  value.family_name
                )
              } else if (type === typeSite[3]) {
                window.location.href = `${localStorage.getItem('site')}`
                listKey.map((e) => localStorage.removeItem(e))
              }
            }
          }

          localStorage.removeItem('email-regis')
          localStorage.removeItem('email-policy')
          localStorage.removeItem('is_reload')
        }
      }
    } catch (error) {
      toast.error(error.data.message)
    }
  }

  useEffect(() => {
    if (localStorage.getItem('email-policy')) {
      setValue('email', localStorage.getItem('email-policy') as string)
    }
  }, [])

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <AccountForm
            control={control}
            searchParams={searchParams}
            setValue={setValue}
            handleCheckEmail={handleCheckEmail}
            loadingCheck={loadingCheck}
            watch={watch}
          />
        )
      case 1:
        return <InfoForm control={control} handleNext={handleNext} handleBack={handleBack} />
      case 2:
        return (
          <AddressForm
            control={control}
            isSubmitting={isSubmitting}
            errors={errors}
            setError={setError}
            clearErrors={clearErrors}
            setValue={setValue}
            watch={watch}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            handleBack={handleBack}
            country={country}
            setCountry={setCountry}
          />
        )
      case 3:
      default:
        return 'ERROR'
    }
  }

  const handleCheckEmail = async () => {
    const isStepValid = await trigger()
    if (isStepValid) {
      setLoadingCheck(true)
      try {
        const res = await api.fetcher('post', '/customer/auth0/exist/email', {
          email: watch('email')
        })

        if (res) {
          localStorage.removeItem('email-policy')
          setSignUpStep(1)
        }
      } catch (error) {
        toast.error(error.data.message)
      } finally {
        setLoadingCheck(false)
      }
    }
  }

  const handleBack = () => {
    setSignUpStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleNext = async () => {
    const isStepValid = await trigger()
    if (isStepValid) setSignUpStep((prevActiveStep) => prevActiveStep + 1)
  }

  return <>{getStepContent(signUpStep)}</>
}

export { RegisterForm }
