import { FormControlLabel, FormControlProps, OutlinedInputProps } from '@mui/material'
import { FieldValues, useController, UseControllerProps } from 'react-hook-form'
import React from 'react'
import { AddControlProps, InputControl } from 'components/components'
import './radio.css'

type SelectOption = {
  label: string
  value: unknown
}

export type RadioProps<T extends FieldValues> = UseControllerProps<T> &
  AddControlProps &
  OutlinedInputProps & {
    controlProps?: FormControlProps
    options: SelectOption[]
    readOnly?: boolean
  }

function Radio<T extends FieldValues>({
  name,
  control,
  options,
  controlProps,
  label,
  helperText,
  required,
  fullWidth,
  disabled,
  readOnly
}: RadioProps<T>) {
  const {
    field: { value, onChange },
    fieldState: { error }
  } = useController({ name, control })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
  }

  return (
    <InputControl
      fieldError={error}
      label={label}
      helperText={helperText}
      required={required}
      fullWidth={fullWidth}
      disabled={disabled}
      {...controlProps}
    >
      <form className="form">
        {options?.map((el: any, index: number) => (
          <FormControlLabel
            key={index}
            value={readOnly ? value : el.value}
            control={<input id={el} type="radio" name={el} checked={el.value == value} />}
            label={el.label}
            onChange={(event) => handleChange(event as React.ChangeEvent<HTMLInputElement>)}
          />
        ))}
      </form>
    </InputControl>
  )
}

export { Radio }
