export const sexOptions = [
  { label: '男性', value: 1 },
  { label: '女性', value: 2 },
  { label: '選択しない', value: 0 }
]

export const typeSite = {
  1: 'kuroco',
  2: 'shopify',
  3: 'kalte'
}

export const listJsonData = [
  { label: 'Hokkaido', value: 1 },
  { label: 'Aomori', value: 2 },
  { label: 'Iwate', value: 3 },
  { label: 'Miyagi', value: 4 },
  { label: 'Akita', value: 5 },
  { label: 'Yamagata', value: 6 },
  { label: 'Fukushima', value: 7 },
  { label: 'Ibaraki', value: 8 },
  { label: 'Tochigi', value: 9 },
  { label: 'Gunma', value: 10 },
  { label: 'Saitama', value: 11 },
  { label: 'Chiba', value: 12 },
  { label: 'Tokyo', value: 13 },
  { label: 'Kanagawa', value: 14 },
  { label: 'Niigata', value: 15 },
  { label: 'Toyama', value: 16 },
  { label: 'Ishikawa', value: 17 },
  { label: 'Fukui', value: 18 },
  { label: 'Yamanashi', value: 19 },
  { label: 'Nagano', value: 20 },
  { label: 'Gifu', value: 21 },
  { label: 'Shizuoka', value: 22 },
  { label: 'Aichi', value: 23 },
  { label: 'Mie', value: 24 },
  { label: 'Shiga', value: 25 },
  { label: 'Kyoto', value: 26 },
  { label: 'Osaka', value: 27 },
  { label: 'Hyogo', value: 28 },
  { label: 'Nara', value: 29 },
  { label: 'Wakayama', value: 30 },
  { label: 'Tottori', value: 31 },
  { label: 'Shimane', value: 32 },
  { label: 'Okayama', value: 33 },
  { label: 'Hiroshima', value: 34 },
  { label: 'Yamaguchi', value: 35 },
  { label: 'Tokushima', value: 36 },
  { label: 'Kagawa', value: 37 },
  { label: 'Ehime', value: 38 },
  { label: 'Kochi', value: 39 },
  { label: 'Fukuoka', value: 40 },
  { label: 'Saga', value: 41 },
  { label: 'Nagasaki', value: 42 },
  { label: 'Kumamoto', value: 43 },
  { label: 'Oita', value: 44 },
  { label: 'Miyazaki', value: 45 },
  { label: 'Kagoshima', value: 46 },
  { label: 'Okinawa', value: 47 }
]

export const dataPrefectureJa = [
  { value: 13, label: '東京都' },
  { value: 1, label: '北海道' },
  { value: 2, label: '青森県' },
  { value: 3, label: '岩手県' },
  { value: 4, label: '宮城県' },
  { value: 5, label: '秋田県' },
  { value: 6, label: '山形県' },
  { value: 7, label: '福島県' },
  { value: 8, label: '茨城県' },
  { value: 9, label: '栃木県' },
  { value: 10, label: '群馬県' },
  { value: 11, label: '埼玉県' },
  { value: 12, label: '千葉県' },
  { value: 14, label: '神奈川県' },
  { value: 15, label: '新潟県' },
  { value: 16, label: '富山県' },
  { value: 17, label: '石川県' },
  { value: 18, label: '福井県' },
  { value: 19, label: '山梨県' },
  { value: 20, label: '長野県' },
  { value: 21, label: '岐阜県' },
  { value: 22, label: '静岡県' },
  { value: 23, label: '愛知県' },
  { value: 24, label: '三重県' },
  { value: 25, label: '滋賀県' },
  { value: 26, label: '京都府' },
  { value: 27, label: '大阪府' },
  { value: 28, label: '兵庫県' },
  { value: 29, label: '奈良県' },
  { value: 30, label: '和歌山県' },
  { value: 31, label: '鳥取県' },
  { value: 32, label: '島根県' },
  { value: 33, label: '岡山県' },
  { value: 34, label: '広島県' },
  { value: 35, label: '山口県' },
  { value: 36, label: '徳島県' },
  { value: 37, label: '香川県' },
  { value: 38, label: '愛媛県' },
  { value: 39, label: '高知県' },
  { value: 40, label: '福岡県' },
  { value: 41, label: '佐賀県' },
  { value: 42, label: '長崎県' },
  { value: 43, label: '熊本県' },
  { value: 44, label: '大分県' },
  { value: 45, label: '宮崎県' },
  { value: 46, label: '鹿児島県' },
  { value: 47, label: '沖縄県' }
]

export const addZero = (num: number) => ('0' + num).slice(-2)

export function getDaysInMonth(year: number, month: number) {
  const date = new Date(year, month, 1)

  const days: number[] = []
  while (date.getMonth() === month) {
    days.push(date.getDate())
    date.setDate(date.getDate() + 1)
  }
  return days
}

export const countryOption = {
  JAPAN: 1,
  ABOARD: 2
}
