import { Box, Button, Container, FormControlLabel, Grid, Stack, Typography } from '@mui/material'
import { Props } from './AccountForm'
import { CheckBox, Input, Select } from 'components'
import { LoadingButton } from '@mui/lab'
import { countryOption, dataPrefectureJa } from 'lib/constant'
import { Link } from 'react-router-dom'
import {
  FieldErrors,
  SubmitHandler,
  UseFormClearErrors,
  UseFormHandleSubmit,
  UseFormSetError,
  UseFormSetValue,
  UseFormWatch
} from 'react-hook-form'
import { useState } from 'react'
import { useAPI } from 'lib/api/api'
import { FormLayout } from 'components/Layouts'
import { useAuth0 } from '@auth0/auth0-react'

type AddressProps = Props & {
  isSubmitting: boolean
  errors: FieldErrors<App.Models.UserRegister>
  setError: UseFormSetError<any>
  clearErrors: UseFormClearErrors<any>
  watch: UseFormWatch<any>
  setValue: UseFormSetValue<any>
  handleSubmit: UseFormHandleSubmit<any, undefined>
  onSubmit: SubmitHandler<App.Models.UserRegister>
  handleBack: () => void
  country: number
  setCountry: React.Dispatch<React.SetStateAction<number>>
}

const AddressForm: React.FC<AddressProps> = ({
  control,
  errors,
  setError,
  clearErrors,
  watch,
  setValue,
  handleSubmit,
  isSubmitting,
  onSubmit,
  handleBack,
  country,
  setCountry
}) => {
  const api = useAPI()
  const [btnLoading, setBtnLoading] = useState<boolean>(false)
  const { logout } = useAuth0()

  const handleNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = ''

    if (country === countryOption.JAPAN) {
      inputValue = event.target.value.replace(/[^0-9]/g, '')
    } else {
      inputValue = event.target.value.replace(/[^a-zA-Z0-9]/g, '').slice(0, 20)
    }

    setValue('postal_code', inputValue)
  }

  const handleChangeZip = async () => {
    setBtnLoading(true)
    try {
      const res = await api.fetcher('get', `/maps/geocode?postal_code=${watch('postal_code')}`)
      if (res.country === '日本') {
        setValue(
          'prefecture',
          Number(dataPrefectureJa.find((e) => e.label === res.administrative_area_level_1)?.value)
        )
        setValue('city', res.locality)
        setValue(
          'address1',
          res.sublocality_level_1
            ? res.sublocality_level_1 + res.sublocality_level_2
            : res.sublocality_level_2
        )
        clearErrors('postal_code')
        clearErrors('prefecture')
        clearErrors('city')
      } else {
        clearErrors('postal_code')
      }
    } catch (error) {
      setError('postal_code', { message: error.data.message })
    } finally {
      setBtnLoading(false)
    }
  }

  const handleChangeCountry = (value: number) => {
    setCountry(value)
    setValue('postal_code', '')

    if (value === countryOption.ABOARD) {
      clearErrors('postal_code')
    }
  }

  return (
    <FormLayout step={3}>
      <Container maxWidth="home" sx={{ px: 0 }}>
        <Box
          sx={{
            backgroundColor: '#FFFEFB',
            p: 2,
            borderRadius: '22px',
            boxShadow: '4px 4px 7px 0px #7775754D, -4px -4px 6px 0px #F7F5F3'
          }}
        >
          <Box>
            <Typography variant="h6" textAlign="center">
              アカウント情報を入力してください
            </Typography>

            <Stack spacing={2} mt={2}>
              <Stack spacing={1}>
                <Stack direction="row">
                  <Typography>郵便番号</Typography>
                  {country === countryOption.JAPAN && (
                    <Typography ml={0.5} variant="caption" color="error.main">
                      *
                    </Typography>
                  )}
                </Stack>

                <form className="form" style={{ marginLeft: 4 }}>
                  <FormControlLabel
                    value="japan"
                    control={
                      <input type="radio" name="japan" checked={country === countryOption.JAPAN} />
                    }
                    label="日本"
                    onChange={() => handleChangeCountry(countryOption.JAPAN)}
                  />

                  <FormControlLabel
                    value="overseas"
                    control={
                      <input
                        type="radio"
                        name="overseas"
                        checked={country === countryOption.ABOARD}
                      />
                    }
                    label="海外"
                    onChange={() => handleChangeCountry(countryOption.ABOARD)}
                  />
                </form>

                <Grid container alignItems="center">
                  <Grid item xs={12} md={4}>
                    <Input
                      control={control}
                      name="postal_code"
                      label=""
                      fullWidth
                      placeholder="例: 1010101"
                      controlProps={{
                        onChange: handleNumber
                      }}
                    />
                  </Grid>

                  {country === countryOption.JAPAN && (
                    <Grid
                      item
                      xs={4}
                      md={2}
                      mt={{ xs: 1, md: errors.postal_code ? -3 : 0 }}
                      ml={{ xs: 1, md: 2 }}
                    >
                      <LoadingButton
                        variant="outlined"
                        sx={{
                          borderRadius: 23,
                          color: '#211D1A',
                          border: '1px solid #211D1A',
                          ':hover': {
                            border: '1px solid #e0deda'
                          }
                        }}
                        onClick={handleChangeZip}
                        loading={btnLoading}
                        className="button"
                      >
                        住所を入力
                      </LoadingButton>
                    </Grid>
                  )}
                </Grid>
              </Stack>

              {country === countryOption.JAPAN && (
                <Select
                  control={control}
                  name="prefecture"
                  label="都道府県"
                  options={dataPrefectureJa}
                  required
                  sx={{ width: { md: '50%' } }}
                  placeholder="都道府県を入力"
                />
              )}

              <Input
                control={control}
                name="city"
                label="市区町村"
                fullWidth
                placeholder="例: 港区"
                required
              />

              <Input
                control={control}
                name="address1"
                label="それ以降の住所"
                fullWidth
                placeholder="例: 新橋3丁目3-13"
              />

              <Input
                control={control}
                name="address2"
                label="建物名、部屋番号など"
                fullWidth
                placeholder="例: ○×マンション 101号室"
              />

              <CheckBox
                control={control}
                name="is_policy"
                required
                label={
                  (
                    <Stack direction="row" sx={{ wordWrap: 'break-word' }}>
                      <Link
                        to="https://www.resonus-account.com/terms/"
                        target="_blank"
                        style={{ color: '#333333' }}
                      >
                        利用規約
                      </Link>
                      <Typography>と</Typography>
                      <Link
                        to="https://www.resonus-account.com/privacy-policy/"
                        target="_blank"
                        style={{ color: '#333333' }}
                      >
                        プライバシーポリシー
                      </Link>
                      <Typography>に同意する</Typography>
                    </Stack>
                  ) as unknown as string
                }
                controlProps={{ sx: { marginX: 0 } }}
              />
            </Stack>
          </Box>

          <Stack alignItems="center" mt={4} direction="row" spacing={2} justifyContent="center">
            <Button
              sx={{
                height: 40,
                borderRadius: 23,
                color: '#989898',
                border: '1px solid #e0deda',
                ':hover': {
                  border: '1px solid #e0deda'
                },
                width: 100
              }}
              onClick={handleBack}
              className="button"
            >
              戻る
            </Button>

            <LoadingButton
              sx={{
                width: 100,
                height: 40,
                borderRadius: 23,
                color: '#111'
              }}
              onClick={handleSubmit(onSubmit)}
              className="buttonVariant"
              loading={isSubmitting}
            >
              登録
            </LoadingButton>
          </Stack>

          <Typography
            style={{
              fontSize: 12,
              color: '#111',
              justifyContent: 'center',
              display: 'flex',
              marginTop: 16,
              textDecoration: 'underline',
              cursor: 'pointer'
            }}
            onClick={() => logout()}
          >
            ログインに戻る
          </Typography>
        </Box>
      </Container>
    </FormLayout>
  )
}

export { AddressForm }
