import { useAuth0 } from '@auth0/auth0-react'
import { LoadingButton } from '@mui/lab'
import { Box, Container, Stack, Typography } from '@mui/material'
import { Input } from 'components'
import { FormLayout } from 'components/Layouts'
import { useEffect } from 'react'
import {
  Control,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormSetValue,
  UseFormWatch
} from 'react-hook-form'

export type Props = {
  control: Control<App.Models.UserRegister, object>
}

type AccountProps = Props & {
  searchParams: URLSearchParams
  setValue: UseFormSetValue<any>
  handleCheckEmail?: () => void
  handleSubmit?: UseFormHandleSubmit<any, undefined>
  onSubmit?: SubmitHandler<App.Models.UserRegister>
  loadingCheck: boolean
  watch?: UseFormWatch<any>
}

const AccountForm: React.FC<AccountProps> = ({
  control,
  searchParams,
  setValue,
  handleCheckEmail,
  loadingCheck,
  handleSubmit,
  onSubmit,
  watch
}) => {
  const { logout } = useAuth0()

  useEffect(() => {
    if (
      (watch && watch('email') !== localStorage.getItem('email-regis')) ||
      (watch && !localStorage.getItem('email-regis')) ||
      (watch && !localStorage.getItem('email-policy'))
    ) {
      setValue('email', watch('email'))
      return
    }
    setValue('email', localStorage.getItem('email-regis') ?? localStorage.getItem('email-policy'))
  }, [])

  return (
    <FormLayout step={1}>
      <Container maxWidth="home" sx={{ px: 0 }}>
        <Box
          sx={{
            backgroundColor: '#FFFEFB',
            p: 2,
            borderRadius: '22px',
            boxShadow: '4px 4px 7px 0px #7775754D, -4px -4px 6px 0px #F7F5F3'
          }}
        >
          <Box>
            <Typography variant="h6" textAlign="center">
              アカウント情報を入力してください
            </Typography>
            <Stack spacing={2} mt={2}>
              <Stack spacing={1}>
                <Input
                  control={control}
                  name="email"
                  label="メールアドレス"
                  required
                  placeholder="メールアドレスを入力"
                />
                <Typography
                  variant="caption"
                  color="#989898"
                  sx={{ '&.MuiTypography-root': { marginLeft: 1 } }}
                >
                  ※スマートフォンの設定によって、携帯会社のメールアドレス(キャリアメール)に確認メールが届かない場合がございます。
                  noreply@resonus-account.comからのメールが受信できるように設定していただくか携帯会社以外(例：gmailなど)のメールアドレスをご使用ください。
                </Typography>
              </Stack>

              {!searchParams.get('policy') && (
                <Stack spacing={2}>
                  <Stack spacing={1}>
                    <Input
                      control={control}
                      name="password"
                      label="パスワード"
                      type="password"
                      required
                      placeholder="パスワードを入力"
                    />
                    <Typography
                      variant="caption"
                      color="#989898"
                      sx={{ '&.MuiTypography-root': { marginLeft: 1 } }}
                    >
                      パスワードは8文字以上で、大文字・小文字・数字・記号のうち3種類以上を使用してください
                    </Typography>
                  </Stack>

                  <Input
                    control={control}
                    name="confirm_password"
                    label="パスワードの確認"
                    type="password"
                    required
                    placeholder="パスワードを再入力"
                  />
                </Stack>
              )}
            </Stack>
          </Box>

          <Stack justifyContent="center" alignItems="center" spacing={3} mt={4}>
            {searchParams.get('policy') ? (
              <LoadingButton
                sx={{
                  width: 100,
                  height: 40,
                  borderRadius: 23,
                  color: '#111'
                }}
                onClick={handleCheckEmail}
                className="buttonVariant"
                loading={loadingCheck}
              >
                次へ
              </LoadingButton>
            ) : (
              <LoadingButton
                sx={{
                  width: 100,
                  height: 40,
                  borderRadius: 23,
                  color: '#111'
                }}
                onClick={handleSubmit && onSubmit && handleSubmit(onSubmit)}
                className="buttonVariant"
                loading={loadingCheck}
                type="submit"
              >
                次へ
              </LoadingButton>
            )}

            <Typography
              style={{
                fontSize: 12,
                color: '#111',
                justifyContent: 'center',
                display: 'flex',
                marginTop: 16,
                textDecoration: 'underline',
                cursor: 'pointer'
              }}
              onClick={() => logout()}
            >
              ログインに戻る
            </Typography>
          </Stack>
        </Box>
      </Container>
    </FormLayout>
  )
}

export { AccountForm }
